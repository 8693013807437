
<template>
  <base-header
    type="gradient-success"
    class="pb-6 pb-8 pt-5 pt-md-8 header_gradiant"
  >
  </base-header>
  <div class="container-fluid mt--7">
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <stats-card>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item router_header">
                <router-link to="/admin/entrevista-social"
                  >Entrevistas-Sociais</router-link
                >
              </li>
              <li class="breadcrumb-item router_header">
                <router-link to="/visualizar-paciente" class="primary"
                  >Visualizar-Entrevista
                </router-link>
              </li>
            </ol>
          </nav>
          <section id="curso_selecionado">
            <div class="container">
              <div>
                <h1 class="title">PACIENTE</h1>
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="nome">NOME</label>
                      <input
                        type="text"
                        class="form-control"
                        id="nome"
                        v-model="state.entrevista.pessoa.nome"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="data_nascimento">DATA DE NASCIMENTO</label>
                      <input
                        type="text"
                        class="form-control"
                        id="data_nascimento"
                        v-model="state.entrevista.pessoa.data_nascimento"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="genero">GÊNERO</label>
                      <input
                        type="text"
                        class="form-control"
                        id="genero"
                        v-model="state.entrevista.pessoa.genero"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="email">EMAIL</label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        v-model="state.entrevista.pessoa.email"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <h1 class="title">DOCUMENTOS</h1>
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="estado_civil">ESTADO CIVIL</label>
                      <select
                        type="text"
                        class="form-select"
                        v-model="state.entrevista.estado_civil"
                      >
                        <option value="SOLTEIRO">Solteiro</option>
                        <option value="CASADO">Casado</option>
                        <option value="VIUVO">Viúvo</option>
                        <option value="OUTROS">Outros</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="rg">RG</label>
                      <input
                        type="text"
                        class="form-control"
                        id="rg"
                        v-mask="'##.###.###-#'"
                        v-model="state.entrevista.rg"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="cpf">CPF</label>
                      <input
                        type="text"
                        class="form-control"
                        id="cpf"
                        v-mask-cpf
                        v-model="state.entrevista.cpf"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="outros">OUTROS</label>
                      <input
                        type="text"
                        class="form-control"
                        id="outros"
                        v-model="state.entrevista.outros"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="nome_responsavel"
                        >NOME RESPONSÁVEL LEGAL</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="nome_responsavel"
                        v-model="state.entrevista.nome_responsavel"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="nome_mae">NOME DA MÃE</label>
                      <input
                        type="text"
                        class="form-control"
                        id="nome_mae"
                        v-model="state.entrevista.nome_mae"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="nome_pai">NOME DO PAI</label>
                      <input
                        type="text"
                        class="form-control"
                        id="nome_pai"
                        v-model="state.entrevista.nome_pai"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="telefone_residencial"
                        >TELEFONE RESIDENCIAL</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="telefone_residencial"
                        v-model="state.entrevista.telefone_residencial"
                        v-mask="'####-####'"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="recado">RECADO</label>
                      <input
                        type="text"
                        class="form-control"
                        id="recado"
                        v-mask="'####-####'"
                        v-model="state.entrevista.telefone_recado"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="celular">CELULAR</label>
                      <input
                        type="text"
                        class="form-control"
                        id="celular"
                        v-model="state.entrevista.pessoa.telefone"
                        v-mask-phone.br
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="comercial">COMERCIAL</label>
                      <input
                        type="text"
                        class="form-control"
                        id="comercial"
                        v-model="state.entrevista.telefone_comercial"
                        v-mask="'####-####'"
                      />
                    </div>
                  </div>
                </div>
                <div class="div-flex">
                  <span class="quest">O PACIENTE É?</span>
                  <div class="form-group">
                    <label class="radio-container"
                      >CRIANÇA
                      <input
                        type="radio"
                        value="CRIANCA"
                        v-model="state.entrevista.faixa_etaria"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="radio-container"
                      >ADOLESCENTE
                      <input
                        type="radio"
                        value="ADOLESCENTE"
                        v-model="state.entrevista.faixa_etaria"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="radio-container"
                      >JOVEM
                      <input
                        type="radio"
                        value="JOVEM"
                        v-model="state.entrevista.faixa_etaria"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="radio-container"
                      >ADULTO
                      <input
                        type="radio"
                        value="ADULTO"
                        v-model="state.entrevista.faixa_etaria"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="radio-container"
                      >IDOSO
                      <input
                        type="radio"
                        value="IDOSO"
                        v-model="state.entrevista.faixa_etaria"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <h1 class="title">DADOS RESIDÊNCIAL</h1>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="rua">RUA</label>
                      <input
                        type="text"
                        class="form-control"
                        id="rua"
                        v-model="state.entrevista.rua"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="num_rua">NÚMERO</label>
                      <input
                        type="text"
                        class="form-control"
                        id="num_rua"
                        v-model="state.entrevista.numero"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>BAIRRO</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="state.entrevista.bairro"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Município</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="state.entrevista.pessoa.cidade"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Estado</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="state.entrevista.pessoa.estado"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>CEP</label>
                      <input
                        class="form-control"
                        v-model="state.entrevista.cep"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label>CARACTERÍSTICAS DE LOCALIZAÇÃO</label>
                      <input
                        class="form-control"
                        v-model="state.entrevista.caracteristicas_local"
                      />
                    </div>
                  </div>
                </div>
                <div class="div-flex">
                  <span class="quest">RESIDÊNCIA:</span>
                  <div class="form-group">
                    <label class="radio-container"
                      >PRÓPRIA
                      <input
                        type="radio"
                        value="PROPRIA"
                        v-model="state.entrevista.residencia"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="radio-container"
                      >ALUGADA
                      <input
                        type="radio"
                        value="ALUGADA"
                        v-model="state.entrevista.residencia"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="radio-container"
                      >CEDIDA
                      <input
                        type="radio"
                        value="CEDIDA"
                        v-model="state.entrevista.residencia"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="radio-container"
                      >FINANCIADA
                      <input
                        type="radio"
                        value="FINANCIADA"
                        v-model="state.entrevista.residencia"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="">
                    <div class="div-outros">
                      <label class="label-outros">OUTROS</label>
                      <input
                        type="text"
                        class="input-outros"
                        v-model="state.entrevista.outros_residencia"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="estado_civil">TIPO DA RESIDÊNCIA</label>
                      <select
                        type="text"
                        class="form-select"
                        v-model="state.entrevista.tipo_residencia"
                      >
                        <option value="ALVENARIA">Alvenaria</option>
                        <option value="MADEIRA">Madeira</option>
                        <option value="MISTO">Misto</option>
                        <option value="OUTROS">Outros</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="nome">QUANTIDADES DE CÔMODOS</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="state.entrevista.quantidade_comodos"
                      />
                    </div>
                  </div>
                </div>
                <div class="">
                  <h3 class="quest">POSSUÍ BANHEIRO?</h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.possui_banheiro"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.possui_banheiro"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="">
                  <h3 class="quest">POSSUÍ SISTEMA DE SANEAMENTO BÁSICO?</h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.possui_saneamento_basico"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.possui_saneamento_basico"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="">
                  <h3 class="quest">TEM ACESSO A ELETRECIDADE?</h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.tem_eletricidade"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.eletrecidade"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="">
                  <h3 class="quest">TEM ACESSO A INTERNET?</h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.tem_internet"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.net"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <h1 class="title">DADOS DE ESCOLARIZAÇÃO</h1>
                <div class="">
                  <h3 class="quest">
                    FREQUENTA ESCOLA, CRECHE OU OUTRA INSTITUIÇÃO DE ENSINO?
                  </h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.frequenta_ensino"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.frequenta_ensino"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div
                    class="col-md-3"
                    v-if="state.entrevista.opcao_ensino == 'SIM'"
                  >
                    <div class="div-outros">
                      <label class="label-outros">QUAL</label>
                      <input
                        type="text"
                        class="input-outros"
                        v-model="state.entrevista.nome_ensino"
                      />
                    </div>
                  </div>
                </div>
                <div class="">
                  <h3 class="quest">QUAL O NÍVEL DE ESCOLARIZAÇÃO?</h3>
                  <label class="radio-container"
                    >NUNCA FREQUENTOU
                    <input
                      type="radio"
                      value="NUNCA_FREQUENTOU"
                      v-model="state.entrevista.nivel_ensino"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >EDUCAÇÃO INFANTIL COMPLETO
                    <input
                      type="radio"
                      value="INFANTIL_COMPLETO"
                      v-model="state.entrevista.nivel_ensino"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >EDUCAÇÃO INFANTIL INCOMPLETO
                    <input
                      type="radio"
                      value="INFANTIL_INCOMPLETO"
                      v-model="state.entrevista.nivel_ensino"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >ENSINO FUNDAMENTAL COMPLETO
                    <input
                      type="radio"
                      value="FUNDAMENTAL_COMPLETO"
                      v-model="state.entrevista.nivel_ensino"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >ENSINO FUNDAMENTAL INCOMPLETO
                    <input
                      type="radio"
                      value="FUNDAMENTAL_INCOMPLETO"
                      v-model="state.entrevista.nivel_ensino"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >ENSINO MÉDIO COMPLETO
                    <input
                      type="radio"
                      value="MEDIO_INCOMPLETO"
                      v-model="state.entrevista.nivel_ensino"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >ENSINO MÉDIO INCOMPLETO
                    <input
                      type="radio"
                      value="MEDIO_COMPLETO"
                      v-model="state.entrevista.nivel_ensino"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÍVEL SUPERIOR
                    <input
                      type="radio"
                      value="SUPERIOR"
                      v-model="state.entrevista.nivel_ensino"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >PÓS GRADUADO
                    <input
                      type="radio"
                      value="POS_GRADUADO"
                      v-model="state.entrevista.nivel_ensino"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >OUTROS
                    <input
                      type="radio"
                      value="OUTROS"
                      v-model="state.entrevista.nivel_ensino"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div
                    div
                    class="col-md-3"
                    v-if="state.entrevista.nivel_ensino == 'OUTROS_ENSINO'"
                  >
                    <div class="div-outros">
                      <label class="label-outros">QUAL</label>
                      <input
                        type="text"
                        class="input-outros"
                        v-model="state.entrevista.nivel_ensino_outros"
                      />
                    </div>
                  </div>
                </div>
                <h1 class="title">DADOS DE BENEFÍCIOS</h1>
                <div class="">
                  <h3 class="quest">RECEBE BOLSA FAMÍLIA?</h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.recebe_bolsa_familia"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.recebe_bolsa_familia"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="">
                  <h3 class="quest">RECEBE BPC?</h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.recebe_bpc"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.recebe_bpc"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >ESTÁ EM ANÁLISE
                    <input
                      type="radio"
                      value="EM_ANALISE"
                      v-model="state.entrevista.recebe_bpc"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="">
                  <h3 class="quest">É ATENDIDO POR ALGUM CRAS OU CREAS?</h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.atendido_cras_creas"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.atendido_cras_creas"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    v-if="state.entrevista.atendido_cras_creas == 'SIM'"
                    class="label-outros"
                    >QUAL</label
                  >
                  <input
                    v-if="state.entrevista.atendido_cras_creas == 'SIM'"
                    type="text"
                    class="input-outros"
                    v-model="state.entrevista.qual_cras_creas"
                  />
                </div>
                <div class="">
                  <h3 class="quest">
                    RECEBE APOSENTADORIA OU AUXILIO DO INSS?
                  </h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.recebe_aposentadoria_inss"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.recebe_aposentadoria_inss"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    v-if="state.entrevista.recebe_aposentadoria_inss == 'SIM'"
                    class="label-outros"
                    >QUAL</label
                  >
                  <input
                    v-if="state.entrevista.recebe_aposentadoria_inss == 'SIM'"
                    type="text"
                    class="input-outros"
                    v-model="state.entrevista.qual_aposentadoria_inss"
                  />
                </div>
                <div class="">
                  <h3 class="quest">RECEBE ATENDIMENTO DE OUTRA ENTIDADE?</h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="
                        state.entrevista.recebe_atendimento_outra_entidade
                      "
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="
                        state.entrevista.recebe_atendimento_outra_entidade
                      "
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    v-if="
                      state.entrevista.recebe_atendimento_outra_entidade ==
                      'SIM'
                    "
                    class="label-outros"
                    >QUAL</label
                  >
                  <input
                    v-if="
                      state.entrevista.recebe_atendimento_outra_entidade ==
                      'SIM'
                    "
                    type="text"
                    class="input-outros"
                    v-model="state.entrevista.qual_atendimento_outra_entidade"
                  />
                </div>
                <div class="">
                  <h3 class="quest">PASSE-LIVRE INTERESTADUAL:</h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.passe_interestadual"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.passe_interestadual"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="">
                  <h3 class="quest">PASSE-LIVRE INTERMUNICIPAL:</h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.passe_intermunicipal"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.passe_intermunicipal"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="">
                  <h3 class="quest">PASSE-LIVRE MUNICIPAL (CIRCULAR):</h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.passe_municipal"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.passe_municipal"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="">
                  <h3 class="quest">
                    POSSUÍ CARTÃO DE ESTACIONAMENTO PARA VAGA ESPECIAL
                  </h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="
                        state.entrevista
                          .possui_cartao_estacionamento_vaga_especial
                      "
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="
                        state.entrevista
                          .possui_cartao_estacionamento_vaga_especial
                      "
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="">
                  <h3 class="quest">POSSUÍ TARIFA SOCIAL DE:</h3>
                  <span class="quest">ÁGUA:</span>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.possui_tarifa_agua"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.possui_tarifa_agua"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span class="quest">ENERGIA:</span>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="state.entrevista.possui_tarifa_energia"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="state.entrevista.possui_tarifa_energia"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <h3 class="quest">PROGRAMA LEITE DAS CRIANÇAS:</h3>
                  <label class="radio-container"
                    >SIM
                    <input
                      type="radio"
                      value="SIM"
                      v-model="
                        state.entrevista.possui_tarifa_leite_das_criancas
                      "
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container"
                    >NÃO
                    <input
                      type="radio"
                      value="NAO"
                      v-model="
                        state.entrevista.possui_tarifa_leite_das_criancas
                      "
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="div-outros">
                  <label class="label-outros"
                    >POSSUÍ OUTRO TIPO DE BENEFÍCIOS:</label
                  >
                  <input
                    type="text"
                    class="input-outros"
                    v-model="state.entrevista.possui_outro_beneficio"
                  />
                </div>
                <h1 class="title">COMPOSIÇÃO FAMILIAR</h1>
                <div class="row">
                  <div class="col-xl-3 col-lg-4 col-md-5">
                    <div class="form-group">
                      <label for="qtd_parentesco"
                        >QUANTIDADE DE PARENTESCO</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="qtd_parentesco"
                        @change="arrayParent"
                        v-model="state.qtd_parentesco"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-for="parente in parseInt(state.qtd_parentesco)"
                  :key="parente"
                >
                  <h1 class="title">PARENTE {{ parente }}</h1>
                  <div class="row">
                    <div class="col-lg-3 col-md-4">
                      <div class="form-group">
                        <label for="nome_parenteco">NOME DO PARENTESCO</label>
                        <input
                          type="text"
                          class="form-control"
                          id="nome_parenteco"
                          v-model="
                            state.entrevista.array_familiares[parente - 1].nome
                          "
                        />
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                      <div class="form-group">
                        <label for="tipo_parentesco">PARENTESCO</label>
                        <input
                          type="text"
                          class="form-control"
                          id="tipo_parentesco"
                          v-model="
                            state.entrevista.array_familiares[parente - 1]
                              .parentesco
                          "
                        />
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                      <div class="form-group">
                        <label for="data_nascimento">DATA DE NASCIMENTO</label>
                        <input
                          type="date"
                          class="form-control"
                          id="data_nascimento"
                          v-model="
                            state.entrevista.array_familiares[parente - 1]
                              .data_nascimento
                          "
                        />
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                      <div class="form-group">
                        <label for="renda_mensal"> RENDA MENSAL</label>
                        <input
                          v-model="
                            state.entrevista.array_familiares[parente - 1].renda
                          "
                          class="form-control"
                          v-mask-decimal.br="2"
                        />
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-5">
                      <div class="form-group">
                        <label for="estado_civil"
                          >POSSUÍ ALGUMA DEFICIÊNCIA</label
                        >
                        <select
                          class="form-select"
                          v-model="
                            state.entrevista.array_familiares[parente - 1]
                              .deficiente
                          "
                        >
                          <option value="SIM">Sim</option>
                          <option value="NAO">Não</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btn_div">
                  <div style="text-align: center" v-if="state.isLoader">
                    <Loader />
                  </div>
                  <button
                    type="button"
                    class="btn_inscricao"
                    :class="{
                      btn_inscricao_loader: state.isLoader == true,
                    }"
                    @click="upEntrevista"
                  >
                    Atualizar
                  </button>
                </div>
              </div>
            </div>
          </section>
        </stats-card>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "./../Site/Layout/loader";
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import services from "../../services";
import { useStorage } from "vue3-storage";
export default {
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    const router = useRouter();
    const state = reactive({
      isLoader: false,
      qtd_parentesco: 0,
      entrevista: {
        opcao_ensino: "",
        id: "",
        nome: "",
        data_nascimento: "",
        email: "",
        genero: "",
        estado_civil: "",
        rg: "",
        cpf: "",
        outros: "",
        nome_responsavel: "",
        nome_mae: "",
        nome_pai: "",
        telefone_residencial: "",
        telefone_recado: "",
        comercial: "",
        paciente: "",
        recebe_bolsa_familia: "",
        bpc: "",
        rua: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        telefone: "",
        residencia: "",
        caracteristicas_local: "",
        outros_residencia: "",
        banheiro: "",
        saneamento: "",
        quantidade_comodos: "",
        eletrecidade: "",
        net: "",
        ensino: "",
        nivel_ensino: "",
        nivel_ensino_outros: "",
        atendido_cras_creas: "",
        qual_cras_creas: "",
        recebe_bpc: "",
        recebe_aposentadoria_inss: "",
        qual_aposentadoria_inss: "",
        recebe_atendimento_outra_entidade: "",
        qual_atendimento_outra_entidade: "",
        passe_interestadual: "",
        passe_intermunicipal: "",
        passe_municipal: "",
        possui_cartao_estacionamento_vaga_especial: "",
        possui_tarifa_agua: "",
        possui_tarifa_energia: "",
        possui_tarifa_leite_das_criancas: "",
        possui_outro_beneficio: "",
        tipo_residencia: "",
        array_familiares: [],
        pessoa: {},
      },
    });
    onMounted(() => {
      if (router.currentRoute._value.params.id != undefined) {
        fetchEntrevista();
      }
    });
    /* async function finalizarCadastro() {
      state.isLoader = true;
      let dados = {
        nome: state.entrevista.nome,
        data_nascimento: state.entrevista.data_nascimento,
        genero: state.entrevista.genero,
        email: state.entrevista.email,
        estado_civil: state.entrevista.estado_civil,
        faixa_etaria: state.entrevista.paciente,
        rg: state.entrevista.rg,
        cpf: state.entrevista.cpf,
        outros: state.entrevista.outros,
        nome_responsavel: state.entrevista.nome_responsavel,
        nome_mae: state.entrevista.nome_mae,
        nome_pai: state.entrevista.nome_pai,
        telefone_celular: state.entrevista.celular,
        telefone_comercial: state.entrevista.comercial,
        telefone_recado: state.entrevista.recado,
        rua: state.entrevista.rua,
        numero: state.entrevista.num_rua,
        bairro: state.entrevista.bairro,
        cep: state.entrevista.cep,
        tipo_residencia: state.entrevista.tipo_residencia,
        caracteristicas_local: state.entrevista.caracteristicas_local,
        residencia: state.entrevista.residencia,
        residencia_outros: state.entrevista.outros_residencia,
        quantidade_comodos: state.entrevista.qtd_comodos,
        possui_banheiro: state.entrevista.banheiro,
        possui_saneamento_basico: state.entrevista.saneamento,
        tem_eletricidade: state.entrevista.eletrecidade,
        tem_internet: state.entrevista.net,
        nome_ensino: state.entrevista.ensino,
        frequenta_ensino: state.entrevista.opcao_ensino,
        nivel_ensino: state.entrevista.nivel_ensino,
        nivel_ensino_outros: state.entrevista.nivel_ensino_outros,
        recebe_bolsa_familia: state.entrevista.recebe_bolsa_familia,
        recebe_bpc: state.entrevista.recebe_bpc,
        atendido_cras_creas: state.entrevista.atendido_cras_creas,
        qual_cras_creas: state.entrevista.qual_cras_creas,
        recebe_aposentadoria_inss: state.entrevista.recebe_aposentadoria_inss,
        qual_aposentadoria_inss: state.entrevista.qual_aposentadoria_inss,
        recebe_atendimento_outra_entidade:
          state.entrevista.recebe_atendimento_outra_entidade,
        qual_atendimento_outra_entidade:
          state.entrevista.qual_atendimento_outra_entidade,
        passe_interestadual: state.entrevista.passe_interestadual,
        passe_intermunicipal: state.entrevista.passe_intermunicipal,
        passe_municipal: state.entrevista.passe_municipal,
        possui_cartao_estacionamento_vaga_especial:
          state.entrevista.possui_cartao_estacionamento_vaga_especial,
        possui_tarifa_agua: state.entrevista.possui_tarifa_agua,
        possui_tarifa_energia: state.entrevista.possui_tarifa_energia,
        possui_tarifa_leite_das_criancas:
          state.entrevista.possui_tarifa_leite_das_criancas,
        possui_outro_beneficio: state.entrevista.possui_outro_beneficio,
        array_familiares: state.entrevista.array_familiares,
      };

      try {
        const { errors } = await services.cadastrar.saveEntrevista(hash, dados);
        if (!errors) {
          router.push("/doe/contribuicao");
          return;
        } else {
          toast.error("Erro ao cadastrar!", {
            autoClose: 2000,
          });
          state.isLoader = false;
          return;
        }
      } catch (error) {
        console.log(error);
        state.isLoader = false;
        toast.error("Erro ao cadastrar!", {
          autoClose: 2000,
        });
        return;
      }
    }*/

    async function fetchEntrevista() {
      try {
        const { data } = await services.paciente.getEntrevistaById({
          id: router.currentRoute._value.params.id,
          token,
        });
        state.entrevista = data;
        state.qtd_parentesco = data.array_familiares.length;
      } catch (error) {
        console.log(error);
      }
    }
    async function arrayParent(event) {
      const element = event.target.value;
      state.qtd_parentesco = element;
      for (let i = 0; i < element; i++) {
        state.entrevista.array_familiares.push({});
      }
      console.log(state.entrevista.array_familiares);
    }
    async function upEntrevista() {
      try {
        const { errors } = await services.paciente.updateEntrevista({
          id: state.entrevista.id,
          token,
          entrevista: state.entrevista,
        });
        if (!errors) {
          router.push("/admin/entrevista-social");
          return;
        } else {
          alert("Erro ao atualizar!");
          state.isLoader = false;
          return;
        }
      } catch (error) {
        console.log(error);
        alert("Erro ao atualizar!");
        state.isLoader = false;
        return;
      }
    }
    return {
      state,
      arrayParent,
      Loader,
      upEntrevista,
    };
  },
};
</script>
<style>
@import url("../../site.css");
</style>
<style scoped>
.header_gradiant {
  background: linear-gradient(
    50deg,
    rgb(82, 181, 174) 0%,
    rgb(10, 36, 35) 100%,
    rgb(22, 82, 78)
  ) !important;
}
h2 {
  color: black;
  font-size: 18pt;
  font-weight: 700;
}
header {
  background-color: rgb(22, 82, 78);
  padding-bottom: 50px;
  height: 90px;
}
.title {
  color: #feb715;
  font-family: Yanone;
}
.div-outros {
  display: flex;
}
.label-outros {
  font-size: 18pt;
  margin-right: 1rem;
}
.input-outros {
  outline: none;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: solid 2px #16524e;
  border-radius: 0;
  padding: 0;
}
.input-outros:focus {
  outline: none;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: solid 2px #16524e;
  border-radius: 0;
  padding: 0;
}
.btn_inscricao_loader {
  display: none !important;
}
.btn_inscricao {
  width: 150px;
  background: #1f6e69;
  color: #fff;
  font-size: 13pt;
  height: 50px;
  border: solid 2px #fff;
  border-radius: 12px;
  transition: 0.4s;
  margin-top: 5px;
  display: block;
}
.btn_div {
  text-align: right;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.btn_inscricao:hover {
  width: 170px;
  color: #feb715;
  border: solid 2px #feb715;
}
˝ .form-group {
  margin-bottom: 0.2rem;
}

#curso_selecionado {
}
a {
  color: rgb(82, 181, 174);
}
a:hover {
  color: rgb(22, 82, 78);
}
label {
  font-size: 16pt;
  font-weight: 400;
  color: rgb(22, 82, 78);
  margin-bottom: 0;
  font-family: Yanone;
}
.form-select {
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 30px;
  padding-left: none !important;
  font-size: 16pt;
  font-family: Yanone;
  padding: 20px;
  width: 100%;
  background: transparent !important;
}
.form-select:focus {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
}
input {
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 30px;
  padding-left: none !important;
  font-size: 16pt;
  font-family: Yanone;
}
input:focus {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
}
input::placeholder {
  color: rgba(17, 62, 59, 0.551);
}
.contribuir {
  color: rgb(22, 82, 78);
  font-size: 24pt;
  text-align: left;
  font-family: Yanone;
  font-weight: 400;
}
.div_img {
  margin-top: 10rem;
  text-align: center;
}
.p_qrcode {
  color: rgb(22, 82, 78);
}
.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 3rem;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 18pt;
  padding-right: 3rem;
}
/* Estilo personalizado para o círculo do botão de opção */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Estilo personalizado para o marcador do botão de opção */
.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #eee;
  border-radius: 50%;
}
/* Estilo personalizado para o marcador quando selecionado */
.radio-container input:checked ~ .checkmark {
  background-color: #16524e;
}
/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
/* Estilo personalizado para o ponto interno do marcador quando selecionado */
.radio-container input:checked ~ .checkmark::after {
  display: block;
}
/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after {
  top: 6px;
  left: 6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
}
label {
  color: #16524e;
  font-weight: 900;
  font-size: 20pt;
  font-family: Yanone;
  margin-top: 10px;
}
.quest {
  color: #16524e;
  font-weight: 600;
  font-size: 18pt;
  font-family: Yanone;
  padding-top: 10px;
  padding-right: 1rem;
}
.div-flex {
  display: flex;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .logoQuatro {
    display: none;
  }
  .radio-container {
    padding-left: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn_div {
    text-align: center;
  }
  .div-flex {
    display: block;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .logoQuatro {
    display: none;
  }
  .btn_div {
    text-align: center;
  }
  .div-flex {
    display: block;
  }
}
@media (max-width: 575px) {
  .logoQuatro {
    display: none;
  }
  .btn_div {
    text-align: center;
  }
  .div-flex {
    display: block;
  }
  .icon_cel {
    display: none;
  }
}
</style>