<template>
  <div class="d-flex d-sm-none">
    <TopCell />
  </div>
  <section id="curso_selecionado">
    <div class="container-fluid" style="background-color: rgb(22, 82, 78)">
      <div class="container">
        <div class="row">
          <MenuMobileDois />
          <div class="col-md-6">
            <h1 class="title_curso">TERMO DE CORRESPONSABILIDADE</h1>
            <div class="d-flex d-sm-none div_nome_curso">
              <img src="/img/icon_apracam/img_cursos.png" class="icon_cel" />
              <div class="titulo_cel">
                FORMULARIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO A
                CANNABIS MEDICINAL
              </div>
            </div>
          </div>
          <div class="col-md-6" style="text-align: right">
            <a href="/">
              <img class="logoQuatro" src="../../../public/img/logo.png"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="quad_agradecimento">
            <p class="p-text">
              Na condição de associado(a) da ASSOCIAÇÃO PARANAENSE DE APOIO A
              CANNABIS MEDICINAL, inscrita no CNPJ 36.716.085/0001-56, declaro
              para os devidos fins de direito que estou compromissado (a) com o
              projeto de cultivo coletivo de Cannabis para fins medicinais,
              desenvolvido pela APRACAM. Declaro que autorizo a Associação, na
              defesa dos melhores interesses dos pacientes associados, a
              representação jurídica e administrativa nos mais diversos órgãos
              da Administração Pública dos três poderes, direta ou indireta,
              para a consecução dos objetivos da coletividade. Declaro, ainda,
              que autorizo e tenho interesse em usufruir de decisão favorável
              aos pedidos judiciais feitos pela Associação Paranaense de Apoio à
              Cannabis Medicinal para garantir o direito ao plantio, cultivo e
              beneficiamento associativo da planta Cannabis para fins
              medicinais.
            </p>
            <p class="p-apracam">APRACAM</p>
          </div>
          <p class="quest">VOCÊ CONCORDA COM NOSSOS TERMOS?</p>
          <label class="radio-container"
            >SIM
            <input type="radio" value="SIM" v-model="state.termos" />
            <span class="checkmark"></span>
          </label>
          <label class="radio-container"
            >NÃO
            <input type="radio" value="NAO" v-model="state.termos" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="btn_div">
        <button @click="termosServico" type="button" class="btn_inscricao">
          CONFIRMAR
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import MenuMobileDois from "../../components/MenuMobileDois.vue";
import TopCell from "../../components/TopCell.vue";
import { reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import services from "../../services";
export default {
  setup() {
    const router = useRouter();
    const storage = useStorage();
    var id = storage.getStorageSync("id");
    console.log(id);
    const state = reactive({
      termos: "",
    });
    onMounted(() => {
      state.metodo = storage.getStorageSync("metodo");
    });

    async function termosServico() {
      if (
        state.termos == null ||
        state.termos == undefined ||
        state.termos == ""
      ) {
        toast.warning("Verifique se você aceita ou não nossos termos!", {
          autoClose: 4000,
        });
        return;
      }
      if (state.termos != "NAO") {
        try {
          if (state.metodo != "nao_contribuinte") {
            const { error } = await services.cadastrar.aprovaCadastro(id);
            if (!error) {
              router.push({ name: "agradecimento" });
            }
          } else {
            router.push({ name: "Finalizar" });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const { error } = await services.cadastrar.cancelaCadastro(id);
          if (!error) {
            router.push({ name: "CadastroCancelado" });
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    return {
      state,
      termosServico,
      MenuMobileDois,
      TopCell,
    };
  },
};
</script>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
}
#curso_selecionado {
  background-color: rgb(255, 255, 255);
  height: auto;
}
.title_curso {
  color: #fff;
  font-size: 25pt;
  padding-top: 20px;
  padding-top: 20px;
  text-align: left;
  font-weight: 100;
}
.logoQuatro {
  position: absolute;
  margin-left: 0;
  left: auto;
  right: auto;
  z-index: 100;
  width: 20%;
}
.btn_inscricao {
  width: 150px;
  height: 50px;
  background: #16524e;
  color: #fff;
  font-size: 16pt;
  border: solid 2px #fff;
  border-radius: 20px;
  transition: 0.4s;
  margin-top: 15px;
  font-family: Yanone;
  font-weight: 300;
}
.btn_div {
  text-align: center;
}
.p-text {
  color: #16524e;
  text-align: justify;
  font-size: 20pt;
  font-family: Yanone;
}
.p-apracam {
  color: #feb715;
  font-size: 18pt;
}
.quad_agradecimento {
  background: #fff;
  margin-top: 3rem;
}
.quest {
  color: #16524e;
  font-size: 18pt;
}
p span {
  color: #feb715;
}
.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 3rem;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 18pt;
  padding-right: 3rem;
}
/* Estilo personalizado para o círculo do botão de opção */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Estilo personalizado para o marcador do botão de opção */
.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #eee;
  border-radius: 50%;
}
/* Estilo personalizado para o marcador quando selecionado */
.radio-container input:checked ~ .checkmark {
  background-color: #16524e;
}
/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
/* Estilo personalizado para o ponto interno do marcador quando selecionado */
.radio-container input:checked ~ .checkmark::after {
  display: block;
}
/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after {
  top: 6px;
  left: 6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
}
label {
  color: #16524e;
  font-weight: 900;
  font-size: 20pt;
  font-family: Yanone;
  margin-top: 10px;
}
header {
  background-color: rgb(22, 82, 78);
  padding-bottom: 50px;
  height: 90px;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .logoQuatro {
  }
  #curso_selecionado {
    height: 100vh;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .logoQuatro {
    position: absolute;
    margin-left: 0;
    left: auto;
    right: 0px;
    z-index: 100;
    width: 20%;
  }
  .title_curso {
    font-size: 20pt;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .logoQuatro {
    display: none;
  }
  #curso_selecionado {
    height: auto;
  }
  .pag-deb {
    margin-top: 1rem;
  }
  .pag-pix {
    margin-top: 1rem;
  }
  .pag-qrcode {
    margin-top: 1rem;
  }
}
@media (max-width: 575px) {
  .title_curso {
    font-size: 20pt;
  }
  .logoQuatro {
    display: none;
  }
  #curso_selecionado {
    height: auto;
  }
  .quad_agradecimento {
    padding: 10px;
  }
  .p-text {
    line-height: 1.4;
    font-size: 18pt;
  }
  .icon_cel {
    margin-right: 20px;
    width: 60px;
    height: 60px;
  }
  .titulo_cel {
    font-family: Yanone;
    font-size: 13pt;
    color: #feb715;
    padding-top: 2rem;
    text-align: right;
  }
  header {
    height: auto;
  }
  .logoQuatro {
    display: none;
  }
}
</style>