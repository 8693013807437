<template>
  <div class="d-flex d-sm-none">
    <TopCell />
  </div>
  <section id="curso_selecionado">
    <header>
      <div class="container">
        <div>
          <div class="row">
            <MenuMobileDois />

            <div class="col-md-6">
              <p class="title_curso" v-if="state.tipo_pessoa == 'PACIENTE'">
                PACIENTE
              </p>
              <p class="title_curso" v-if="state.tipo_pessoa == 'TUTOR_PET'">
                PET
              </p>
              <h1
                class="title_curso"
                v-if="state.tipo_pessoa == 'PROFISSIONAL_SAUDE'"
              >
                PROFISSIONAL DE SAÚDE
              </h1>
              <p class="title_curso" v-if="state.tipo_pessoa == 'PESQUISADOR'">
                PESQUISADOR
              </p>
              <p class="title_curso" v-if="state.tipo_pessoa == 'PARCEIRO'">
                PARCEIRO
              </p>
              <div class="d-flex d-sm-none div_nome_curso">
                <img src="/img/icon_apracam/img_cursos.png" class="icon_cel" />
                <div class="titulo_cel">
                  FORMULARIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO
                  A CANNABIS MEDICINAL
                </div>
              </div>
            </div>
            <div class="col-md-6" style="text-align: right">
              <a href="/">
                <img class="logoQuatro" src="../../../public/img/logo.png"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="container" v-if="state.tipo_pessoa == 'PACIENTE'">
      <div class="div_form">
        <div style="background-color: #fff; border-radius: 20px">
          <div class="row">
            <div class="col-md-5">
              <label> POSSUI RECEITA? </label>
              <div class="form-group">
                <label class="radio-container">
                  <div class="borda">
                    <p>
                      JÁ TENHO RECEITA.<br />
                      QUERO SER ACOLHIDO.
                    </p>
                  </div>
                  <input
                    type="radio"
                    name="opcao"
                    value="sim"
                    v-model="state.tem_receita"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group">
                <label class="radio-container">
                  <div class="borda">
                    <p>
                      NÃO TENHO RECEITA,<br />
                      QUERO SER ACOLHIDO.
                    </p>
                  </div>
                  <input
                    type="radio"
                    name="opcao"
                    value="nao"
                    v-model="state.tem_receita"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>

              <div v-if="state.tem_receita == 'sim'">
                <label for="receita">RECEITA MÉDICA</label
                ><span class="campo-obrigatorio">(Obrigatório*)</span>
                <div
                  class="inputFile"
                  v-if="Object.keys(state.receita).length === 0"
                >
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="receita"
                    @change="receitaMedica"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="
                      state.receita.file.type == 'image/jpeg' ||
                      state.receita.file.type == 'image/jpg' ||
                      state.receita.file.type == 'image/png'
                    "
                  >
                    <img :src="state.receita.receita" style="width: 100px" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarReceitaMedica"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <img src="../../../public/img/pdf.png" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarReceitaMedica"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                </div>
              </div>

              <label for="laudo">LAUDO MÉDICO</label>
              <div
                v-if="Object.keys(state.laudo).length === 0"
                class="inputFile"
              >
                <span class="arquivo">Selecione um arquivo</span>
                <input
                  type="file"
                  class="form-control"
                  id="laudo"
                  @change="laudoMedico"
                />
              </div>
              <div v-else>
                <div
                  v-if="
                    state.laudo.file.type == 'image/jpeg' ||
                    state.laudo.file.type == 'image/jpg' ||
                    state.laudo.file.type == 'image/png'
                  "
                >
                  <img :src="state.laudo.laudo" style="width: 100px" />
                  <button
                    type="button"
                    class="btn_deletar"
                    @click="deletarLaudoMedico"
                  >
                    <img
                      src="../../../public/img/btn_deletar.png"
                      class="img_deletar"
                    />
                  </button>
                </div>
                <div v-else>
                  <img src="../../../public/img/pdf.png" />
                  <button
                    type="button"
                    class="btn_deletar"
                    @click="deletarLaudoMedico"
                  >
                    <img
                      src="../../../public/img/btn_deletar.png"
                      class="img_deletar"
                    />
                  </button>
                </div>
              </div>
              <div class="form-group">
                <label for="patologia">PATOLOGIA (DOENÇA)</label
                ><span class="campo-obrigatorio">(Obrigatório*)</span>
                <input
                  type="text"
                  class="form-control"
                  id="patologia"
                  v-model="state.patologia"
                />
              </div>
              <div class="form-group">
                <h2 class="contribuir">POSSUÍ RESPONSÁVEL LEGAL?</h2>
                <label class="radio-container">
                  <div class="borda conteudo_responsavel">NÃO.</div>
                  <input
                    type="radio"
                    value="nao"
                    v-model="state.opcao_responsavel"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group">
                <label class="radio-container">
                  <div class="borda conteudo_responsavel">SIM.</div>
                  <input
                    type="radio"
                    value="sim"
                    v-model="state.opcao_responsavel"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group" v-if="state.opcao_responsavel == 'sim'">
                <label for="nome_responsavel">NOME DO RESPONSÁVEL</label>
                <input
                  type="text"
                  class="form-control"
                  id="nome_responsavel"
                  v-model="state.nome_responsavel"
                />
                <label for="receita">DOCUMENTO DO RESPONSÁVEL</label>
                <div
                  class="inputFile"
                  v-if="Object.keys(state.doc_responsavel).length === 0"
                >
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="receita"
                    @change="docResponsavel"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="
                      state.doc_responsavel.file.type == 'image/jpeg' ||
                      state.doc_responsavel.file.type == 'image/jpg' ||
                      state.doc_responsavel.file.type == 'image/png'
                    "
                  >
                    <img
                      :src="state.doc_responsavel.doc_responsavel"
                      style="width: 100px"
                    />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarDocR"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <img src="../../../public/img/pdf.png" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarReceitaMedica"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <label for="documento"
                >DOCUMENTO DE IDENTIFICAÇÃO (PACIENTE)</label
              ><span class="campo-obrigatorio">(Obrigatório*)</span>
              <div
                class="inputFile"
                v-if="Object.keys(state.doc_paciente).length === 0"
              >
                <span class="arquivo">Selecione um arquivo</span>
                <input
                  type="file"
                  class="form-control"
                  id="documento"
                  @change="docPaciente"
                />
              </div>
              <div v-else>
                <div
                  v-if="
                    state.doc_paciente.file.type == 'image/jpeg' ||
                    state.doc_paciente.file.type == 'image/jpg' ||
                    state.doc_paciente.file.type == 'image/png'
                  "
                >
                  <img
                    :src="state.doc_paciente.doc_paciente"
                    style="width: 100px"
                  />
                  <button
                    type="button"
                    class="btn_deletar"
                    @click="deletarDocP"
                  >
                    <img
                      src="../../../public/img/btn_deletar.png"
                      class="img_deletar"
                    />
                  </button>
                </div>
                <div v-else>
                  <img src="../../../public/img/pdf.png" />
                  <button
                    type="button"
                    class="btn_deletar"
                    @click="deletarDocP"
                  >
                    <img
                      src="../../../public/img/btn_deletar.png"
                      class="img_deletar"
                    />
                  </button>
                </div>
              </div>
              <label for="residencia">COMPROVANTE DE RESIDÊNCIA</label>
              <div
                class="inputFile"
                v-if="Object.keys(state.comprovante_residencia).length === 0"
              >
                <span class="arquivo">Selecione um arquivo</span>

                <input
                  type="file"
                  class="form-control"
                  id="residencia"
                  @change="comprovanteResidencia"
                />
              </div>
              <div v-else>
                <div
                  v-if="
                    state.comprovante_residencia.file.type == 'image/jpeg' ||
                    state.comprovante_residencia.file.type == 'image/jpg' ||
                    state.comprovante_residencia.file.type == 'image/png'
                  "
                >
                  <img
                    :src="state.comprovante_residencia.residencia"
                    style="width: 100px"
                  />
                  <button
                    type="button"
                    class="btn_deletar"
                    @click="deletarComprovanteResidencia"
                  >
                    <img
                      src="../../../public/img/btn_deletar.png"
                      class="img_deletar"
                    />
                  </button>
                </div>
                <div v-else>
                  <img src="../../../public/img/pdf.png" />
                </div>
              </div>
              <!--<div class="form-group">
                  <h2 class="contribuir">
                    GOSTARIA DE CONTRIBUIR COM A APRACAM?
                  </h2>
                  <label class="radio-container"
                    >NÃO.
                    <input
                      type="radio"
                      name="opcao"
                      value="nao"
                      v-model="state.contribuicao"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-group">
                  <label class="radio-container">
                    SIM.
                    <input
                      type="radio"
                      name="opcao"
                      value="sim"
                      v-model="state.contribuicao"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>-->
              <div class="btn_div">
                <div style="text-align: center" v-if="state.isLoader">
                  <Loader />
                </div>

                <button
                  @click="prosseguirPaciente"
                  type="button"
                  class="btn_inscricao"
                  :class="{
                    btn_inscricao_loader: state.isLoader == true,
                  }"
                >
                  PROSSEGUIR
                </button>
              </div>
            </div>
            <div class="col-md-4 d-none d-sm-block">
              <div class="text_form">
                <h1 class="form_text">
                  FORMULÁRIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO
                  A CANNABIS MEDICINAL
                </h1>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="div_img">
                <img
                  class="icon_img"
                  src="../../../public/img/icon_apracam/seringa.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="state.tipo_pessoa == 'TUTOR_PET'">
      <div style="background-color: #fff; border-radius: 20px">
        <div class="row">
          <div class="col-md-5">
            <div class="div_form">
              <form>
                <label for="receita">RECEITA MÉDICA</label
                ><span class="campo-obrigatorio">(Obrigatório*)</span>
                <div
                  class="inputFile"
                  v-if="Object.keys(state.receita).length === 0"
                >
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="receita"
                    @change="receitaMedica"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="
                      state.receita.file.type == 'image/jpeg' ||
                      state.receita.file.type == 'image/jpg' ||
                      state.receita.file.type == 'image/png'
                    "
                  >
                    <img :src="state.receita.receita" style="width: 100px" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarReceitaMedica"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <img src="../../../public/img/pdf.png" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarReceitaMedica"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                </div>
                <div class="form-group">
                  <label for="laudo">NOME DO PET</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input
                    type="text"
                    class="form-control"
                    id="laudo"
                    v-model="state.nome_pet"
                    placeholder="Ex: Pipoca"
                  />
                </div>
                <div class="form-group">
                  <label for="patologia">PATOLOGIA (DOENÇA)</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input
                    type="text"
                    class="form-control"
                    id="patologia"
                    v-model="state.patologia"
                  />
                </div>
                <div class="form-group">
                  <label for="tutor">TUTOR PET</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input
                    type="text"
                    class="form-control"
                    id="tutor"
                    v-model="state.tutor_pet"
                    placeholder="Ex: Jefferson"
                  />
                </div>
                <label for="documento"
                  >DOCUMENTO DE IDENTIFICAÇÃO (RG/CPF/CNH)</label
                ><span class="campo-obrigatorio">(Obrigatório*)</span>
                <div
                  class="inputFile"
                  v-if="Object.keys(state.doc_responsavel).length === 0"
                >
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="documento"
                    @change="docResponsavel"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="
                      state.doc_responsavel.file.type == 'image/jpeg' ||
                      state.doc_responsavel.file.type == 'image/jpg' ||
                      state.doc_responsavel.file.type == 'image/png'
                    "
                  >
                    <img
                      :src="state.doc_responsavel.doc_responsavel"
                      style="width: 100px"
                    />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarDocR"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <img src="../../../public/img/pdf.png" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarDocR"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                </div>

                <label for="residencia">COMPROVANTE DE RESIDÊNCIA</label>
                <div
                  class="inputFile"
                  v-if="Object.keys(state.comprovante_residencia).length === 0"
                >
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="residencia"
                    @change="comprovanteResidencia"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="
                      state.comprovante_residencia.file.type == 'image/jpeg' ||
                      state.comprovante_residencia.file.type == 'image/jpg' ||
                      state.comprovante_residencia.file.type == 'image/png'
                    "
                  >
                    <img
                      :src="state.comprovante_residencia.residencia"
                      style="width: 100px"
                    />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarComprovanteResidencia"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <img src="../../../public/img/pdf.png" />
                  </div>
                </div>

                <div class="btn_div">
                  <div style="text-align: center" v-if="state.isLoader">
                    <Loader />
                  </div>
                  <button
                    @click="prosseguirPaciente"
                    type="button"
                    class="btn_inscricao"
                    :class="{
                      btn_inscricao_loader: state.isLoader == true,
                    }"
                  >
                    PROSSEGUIR
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="col-md-4">
            <div class="text_form">
              <h1 class="form_text">
                FORMULÁRIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO A
                CANNABIS MEDICINAL
              </h1>
            </div>
          </div>
          <div class="col-md-3">
            <div class="div_img">
              <img
                class="icon_img"
                src="../../../public/img/icon_apracam/loves_pet.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="state.tipo_pessoa == 'PROFISSIONAL_SAUDE'">
      <div style="background-color: #fff; border-radius: 20px">
        <div class="row">
          <div class="col-md-4 offset-1">
            <div class="div_form">
              <form>
                <div class="form-group">
                  <label for="formacao">FORMAÇÃO</label>
                  <input
                    type="text"
                    class="form-control"
                    id="formacao"
                    v-model="state.formacao"
                    placeholder="Ex: Ortopedista"
                  />
                </div>
                <div class="form-group">
                  <label for="proposta">PROPOSTA DE PARCERIA</label>
                  <textarea
                    class="form-control"
                    id="proposta"
                    v-model="state.proposta_parceria"
                  ></textarea>
                </div>
                <label for="anexo">ANEXOS</label>

                <div
                  class="inputFile"
                  v-if="Object.keys(state.anexo).length === 0"
                >
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="anexo"
                    @change="anexo"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="
                      state.anexo.file.type == 'image/jpeg' ||
                      state.anexo.file.type == 'image/jpg' ||
                      state.anexo.file.type == 'image/png'
                    "
                  >
                    <img :src="state.anexo.anexo" style="width: 100px" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarAnexo"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <img src="../../../public/img/pdf.png" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarAnexo"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                </div>

                <label for="anexoUm">ANEXOS</label>
                <div
                  class="inputFile"
                  v-if="Object.keys(state.anexoUm).length === 0"
                >
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="anexoUm"
                    @change="anexoUm"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="
                      state.anexoUm.file.type == 'image/jpeg' ||
                      state.anexoUm.file.type == 'image/jpg' ||
                      state.anexoUm.file.type == 'image/png'
                    "
                  >
                    <img :src="state.anexoUm.anexoUm" style="width: 100px" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarAnexoUm"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <img src="../../../public/img/pdf.png" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarAnexoUm"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                </div>

                <div class="btn_div">
                  <div style="text-align: center" v-if="state.isLoader">
                    <Loader />
                  </div>
                  <button
                    @click="prosseguir"
                    type="button"
                    class="btn_inscricao"
                    :class="{
                      btn_inscricao_loader: state.isLoader == true,
                    }"
                  >
                    PROSSEGUIR
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-4 d-none d-sm-block">
            <div class="text_form_profissional">
              <h1 class="form_text">
                FORMULÁRIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO A
                CANNABIS MEDICINAL
              </h1>
            </div>
          </div>
          <div class="col-md-3">
            <div class="div_img">
              <img
                class="icon_img"
                src="../../../public/img/icon_apracam/icon_equipe.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="state.tipo_pessoa == 'PESQUISADOR'">
      <div style="background-color: #fff; border-radius: 20px">
        <div class="row">
          <div class="col-md-4 offset-1">
            <div class="div_form">
              <form>
                <div class="form-group">
                  <label for="formacao">FORMAÇÃO</label>
                  <input
                    type="text"
                    class="form-control"
                    id="formacao"
                    v-model="state.formacao"
                    placeholder="Ex: Biólogo"
                  />
                </div>
                <div class="form-group">
                  <label for="proposta">PROPOSTA DE PARCERIA</label>
                  <textarea
                    class="form-control"
                    id="proposta"
                    v-model="state.proposta_parceria"
                  ></textarea>
                </div>

                <label for="anexo">ANEXOS</label>
                <div
                  class="inputFile"
                  v-if="Object.keys(state.anexo).length === 0"
                >
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="anexo"
                    @change="anexo"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="
                      state.anexo.file.type == 'image/jpeg' ||
                      state.anexo.file.type == 'image/jpg' ||
                      state.anexo.file.type == 'image/png'
                    "
                  >
                    <img :src="state.anexo.anexo" style="width: 100px" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarAnexo"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <img src="../../../public/img/pdf.png" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarAnexo"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                </div>

                <label for="anexo">ANEXOS</label>
                <div
                  class="inputFile"
                  v-if="Object.keys(state.anexoUm).length === 0"
                >
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="anexoUm"
                    @change="anexoUm"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="
                      state.anexoUm.file.type == 'image/jpeg' ||
                      state.anexoUm.file.type == 'image/jpg' ||
                      state.anexoUm.file.type == 'image/png'
                    "
                  >
                    <img :src="state.anexoUm.anexoUm" style="width: 100px" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarAnexoUm"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <img src="../../../public/img/pdf.png" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarAnexoUm"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                </div>

                <div class="btn_div">
                  <div style="text-align: center" v-if="state.isLoader">
                    <Loader />
                  </div>
                  <button
                    @click="prosseguir"
                    type="button"
                    class="btn_inscricao"
                    :class="{
                      btn_inscricao_loader: state.isLoader == true,
                    }"
                  >
                    PROSSEGUIR
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-4 d-none d-sm-block">
            <div class="text_form_profissional">
              <h1 class="form_text">
                FORMULÁRIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO A
                CANNABIS MEDICINAL
              </h1>
            </div>
          </div>
          <div class="col-md-3">
            <div class="div_img">
              <img
                class="icon_img"
                src="../../../public/img/icon_apracam/icon_equipe.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="state.tipo_pessoa == 'PARCEIRO'">
      <div style="background-color: #fff; border-radius: 20px">
        <div class="row">
          <div class="col-md-6">
            <div class="div_form">
              <form>
                <div class="form-group">
                  <label for="proposta">PROPOSTA DE PARCERIA</label>
                  <textarea
                    class="form-control"
                    id="proposta"
                    v-model="state.proposta_parceria"
                  ></textarea>
                </div>
                <label for="anexo">ANEXOS</label>
                <div
                  class="inputFile"
                  v-if="Object.keys(state.anexo).length === 0"
                >
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="anexo"
                    @change="anexo"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="
                      state.anexo.file.type == 'image/jpeg' ||
                      state.anexo.file.type == 'image/jpg' ||
                      state.anexo.file.type == 'image/png'
                    "
                  >
                    <img :src="state.anexo.anexo" style="width: 100px" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarAnexo"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <img src="../../../public/img/pdf.png" />

                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarAnexo"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                </div>
                <label for="anexo">ANEXOS</label>
                <div
                  class="inputFile"
                  v-if="Object.keys(state.anexoUm).length === 0"
                >
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="anexoUm"
                    @change="anexoUm"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="
                      state.anexoUm.file.type == 'image/jpeg' ||
                      state.anexoUm.file.type == 'image/jpg' ||
                      state.anexoUm.file.type == 'image/png'
                    "
                  >
                    <img :src="state.anexoUm.anexoUm" style="width: 100px" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarAnexoUm"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <img src="../../../public/img/pdf.png" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarAnexoUm"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                </div>
                <div class="btn_div">
                  <div style="text-align: center" v-if="state.isLoader">
                    <Loader />
                  </div>
                  <button
                    @click="prosseguir"
                    type="button"
                    class="btn_inscricao"
                    :class="{
                      btn_inscricao_loader: state.isLoader == true,
                    }"
                  >
                    PROSSEGUIR
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-4 d-none d-sm-block">
            <div class="text_form_profissional">
              <h1 class="form_text">
                FORMULÁRIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO A
                CANNABIS MEDICINAL
              </h1>
            </div>
          </div>
          <div class="col-md-2">
            <div class="div_img">
              <img
                class="icon_img"
                src="../../../public/img/icon_apracam/estufa_icon.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  <script>
import MenuMobileDois from "../../components/MenuMobileDois.vue";
import TopCell from "../../components/TopCell.vue";
import Loader from "./../Site/Layout/loader";
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import services from "../../services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  setup() {
    const storage = useStorage();
    const router = useRouter();
    const state = reactive({
      //PACIENTE
      tem_receita: "sim",
      isLoader: false,
      patologia: "",
      nome_responsavel: "",
      opcao_responsavel: "nao",
      documento: "",
      nome_pet: "",
      tutor_pet: "",
      formacao: "",
      proposta_parceria: "",
      hash: "",
      comprovante_residencia: {},
      receita: {},
      doc_responsavel: {},
      laudo: {},
      doc_paciente: {},
      arquivo: [],
      anexoUm: {},
      anexo: {},
    });
    onMounted(() => {
      if (router.currentRoute._value.params.tipo_pessoa != undefined) {
        state.tipo_pessoa = router.currentRoute._value.params.tipo_pessoa;
      }
      state.nome = storage.getStorageSync("nome");
      state.email = storage.getStorageSync("email");
      state.telefone = storage.getStorageSync("telefone");
      state.genero = storage.getStorageSync("genero");
      state.estado = storage.getStorageSync("estado");
      state.cidade = storage.getStorageSync("cidade");
      state.cep = storage.getStorageSync("cep");
      state.endereco = storage.getStorageSync("endereco");
      state.data_nascimento = storage.getStorageSync("data_nascimento");
      state.rua = storage.getStorageSync("rua");
      state.numero = storage.getStorageSync("numero");
      state.bairro = storage.getStorageSync("bairro");
      state.pessoa = storage.getStorageSync("pessoa");
      upperCase();
    });

    async function comprovanteResidencia(event) {
      const element = event.target.files[0];
      var objResidencia = new Object();
      //   objImagem.id = 0 + 1;
      objResidencia.file = element;
      objResidencia.residencia = URL.createObjectURL(element);
      state.comprovante_residencia = objResidencia;
    }
    async function deletarComprovanteResidencia() {
      state.comprovante_residencia = {};
    }

    async function receitaMedica(event) {
      const element = event.target.files[0];
      var objReceita = new Object();
      //   objImagem.id = 0 + 1;
      objReceita.file = element;
      objReceita.receita = URL.createObjectURL(element);
      state.receita = objReceita;
      console.log(state.receita.file);
    }
    async function deletarReceitaMedica() {
      state.receita = {};
    }

    async function docPaciente(event) {
      const element = event.target.files[0];
      var objDoc = new Object();
      //   objImagem.id = 0 + 1;
      objDoc.file = element;
      objDoc.doc_paciente = URL.createObjectURL(element);
      state.doc_paciente = objDoc;
      console.log(state.doc_paciente.file);
    }
    async function deletarDocP() {
      state.doc_paciente = {};
    }

    async function docResponsavel(event) {
      const element = event.target.files[0];
      var objDoc = new Object();
      //   objImagem.id = 0 + 1;
      objDoc.file = element;
      objDoc.doc_responsavel = URL.createObjectURL(element);
      state.doc_responsavel = objDoc;
      console.log(state.doc_responsavel.file);
    }
    async function deletarDocR() {
      state.doc_responsavel = {};
    }

    async function laudoMedico(event) {
      const element = event.target.files[0];
      var objLaudo = new Object();
      //   objImagem.id = 0 + 1;
      objLaudo.file = element;
      objLaudo.laudo = URL.createObjectURL(element);
      state.laudo = objLaudo;
      console.log(state.laudo.file);
    }
    async function deletarLaudoMedico() {
      state.laudo = {};
    }

    async function anexo(event) {
      //  console.log();
      const element = event.target.files[0];
      var objanexo = new Object();
      //   objImagem.id = 0 + 1;
      objanexo.file = element;
      objanexo.anexo = URL.createObjectURL(element);
      state.anexo = objanexo;
    }
    async function deletarAnexo() {
      state.anexo = {};
    }

    async function anexoUm(event) {
      const element = event.target.files[0];
      var objanexoUm = new Object();
      //   objImagem.id = 0 + 1;
      objanexoUm.file = element;
      objanexoUm.anexoUm = URL.createObjectURL(element);
      state.anexoUm = objanexoUm;
      console.log(state.anexoUm.file);
    }
    async function deletarAnexoUm() {
      state.anexoUm = {};
    }
    async function prosseguirPaciente() {
      if (state.tem_receita == "sim") {
        if (
          state.receita.file == null ||
          state.receita.file == undefined ||
          state.receita.file == {}
        ) {
          toast.error("Insira uma receita!", {
            autoClose: 2000,
          });
          return;
        }
      }

      if (
        state.patologia == null ||
        state.patologia == undefined ||
        state.patologia == ""
      ) {
        toast.error("Insira uma patologia !", {
          autoClose: 2000,
        });
        return;
      }

      if (state.pessoa == "PACIENTE") {
        if (
          state.doc_paciente.file == null ||
          state.doc_paciente.file == undefined ||
          state.doc_paciente.file == {}
        ) {
          toast.error("Insira o documento de identificação do paciente !", {
            autoClose: 2000,
          });
          return;
        }
      }
      if (state.opcao_responsavel == "sim") {
        if (
          state.doc_responsavel.file == null ||
          state.doc_responsavel.file == undefined ||
          state.doc_responsavel.file == {}
        ) {
          toast.error("Insira o documento de identificação do responsável !", {
            autoClose: 2000,
          });
          return;
        }
      }
      state.isLoader = true;
      var formPaciente = new FormData();
      formPaciente.append("nome", state.nome);
      formPaciente.append("email", state.email);
      formPaciente.append("telefone", state.telefone);
      formPaciente.append("genero", state.genero);
      formPaciente.append("estado", state.estado);
      formPaciente.append("cidade", state.cidade);
      formPaciente.append("cep", state.cep);
      formPaciente.append("endereco", state.endereco);
      formPaciente.append("data_nascimento", state.data_nascimento);
      formPaciente.append("tipo_pessoa", state.pessoa);
      if (state.receita.file != undefined && state.receita.file != null) {
        formPaciente.append("receita", state.receita.file);
      }
      formPaciente.append("patologia", state.patologia);
      formPaciente.append("responsavel", state.nome_responsavel);
      formPaciente.append("rua", state.rua);
      formPaciente.append("numero", state.numero);
      formPaciente.append("bairro", state.bairro);
      storage.setStorageSync("nome_responsavel", state.nome_responsavel);

      if (state.pessoa == "PET" || state.pessoa == "PACIENTE") {
        formPaciente.append("doc_paciente", state.doc_paciente.file);
      }
      if (
        state.doc_responsavel.file != undefined &&
        state.doc_responsavel.file != null
      ) {
        formPaciente.append("doc_responsavel", state.doc_responsavel.file);
      }
      if (state.laudo.file != undefined && state.laudo.file != null) {
        formPaciente.append("laudo", state.laudo.file);
      }
      if (
        state.comprovante_residencia.file != undefined &&
        state.comprovante_residencia.file != null
      ) {
        formPaciente.append(
          "comprovante_residencia",
          state.comprovante_residencia.file
        );
      }
      formPaciente.append("nome_pet", state.nome_pet);
      formPaciente.append("tutor_pet", state.tutor_pet);
      services.cadastrar
        .cadastrarPaciente(formPaciente)
        .then((data) => {
          if (data.data.pessoa_id) {
            storage.setStorageSync("hash", data.data.hash);
            storage.setStorageSync("id", data.data.id);
            router.push("/doe/contribuicao");
          } else {
            toast.error("Erro ao cadastrar!", {
              autoClose: 2000,
            });
            state.isLoader = false;
            return;
          }
        })
        .catch((error) => {
          var valores = Object.values(error.response.data);
          valores.forEach((element) => {
            element.forEach((element2) => {
              toast(element2, {
                type: "error",
                autoClose: 3000,
              });
            });
          });
          state.isLoader = false;
          return;
        });
    }
    async function prosseguir() {
      try {
        if (
          state.pessoa == "PROFISSIONAL_SAUDE" ||
          state.pessoa == "PESQUISADOR"
        ) {
          if (
            state.formacao == null ||
            state.formacao == undefined ||
            state.formacao == ""
          ) {
            toast.error("Insira uma formação !", {
              autoClose: 2000,
            });
            return;
          }
        }
        if (
          state.proposta_parceria == null ||
          state.proposta_parceria == undefined ||
          state.proposta_parceria == ""
        ) {
          toast.error("Insira uma proposta de paraceria !", {
            autoClose: 2000,
          });
          return;
        }
        /* if (
          state.contribuicao == null ||
          state.contribuicao == undefined ||
          state.contribuicao == ""
        ) {
          toast.error(
            "Por favor escolha se deseja contribuir com a APRACAM !",
            {
              autoClose: 2000,
            }
          );
          return;
        }*/
        state.isLoader = true;
        var formProfissional = new FormData();
        formProfissional.append("nome", state.nome);
        formProfissional.append("email", state.email);
        formProfissional.append("telefone", state.telefone);
        formProfissional.append("genero", state.genero);
        formProfissional.append("estado", state.estado);
        formProfissional.append("cidade", state.cidade);
        formProfissional.append("endereco", state.endereco);
        formProfissional.append("data_nascimento", state.data_nascimento);
        formProfissional.append("tipo_pessoa", state.pessoa);
        formProfissional.append("rua", state.rua);
        formProfissional.append("numero", state.numero);
        formProfissional.append("bairro", state.bairro);
        formProfissional.append("cep", state.cep);
        if (state.anexo.file != undefined && state.anexo.file != null) {
          formProfissional.append("anexoDois", state.anexo.file);
        }
        if (state.anexoUm.file != undefined && state.anexoUm.file != null) {
          formProfissional.append("anexoUm", state.anexoUm.file);
        }
        if (
          state.formacao != undefined &&
          state.formacao != null &&
          state.formacao != ""
        ) {
          formProfissional.append("formacao", state.formacao);
        }
        formProfissional.append("proposta_parceria", state.proposta_parceria);
        services.cadastrar
          .cadastrarProfissional(formProfissional)
          .then(() => {
            toast.success("Cadastro Realizado com Sucesso!", {
              autoClose: 2000,
            });
            setTimeout(2000);
            router.push("/");
          })

          .catch((error) => {
            console.log(error);
            state.isLoader = false;
            toast.error("Erro ao cadastrar!", {
              autoClose: 2000,
            });
            return;
          });
        //storage.setStorageSync("contribuicao", state.contribuicao);
        return;
      } catch (error) {
        state.isLoader = false;
        toast.error("Erro ao cadastrar!", {
          autoClose: 2000,
        });
        return;
      }
    }
    function upperCase() {
      var title = state.tipo_pessoa;
      //-> Convertendo a String para maiúsculo.
      state.tipo_pessoa = title.toUpperCase();
    }
    return {
      Loader,
      state,
      upperCase,
      prosseguir,
      deletarReceitaMedica,
      prosseguirPaciente,
      comprovanteResidencia,
      deletarComprovanteResidencia,
      receitaMedica,
      laudoMedico,
      deletarLaudoMedico,
      anexoUm,
      anexo,
      docResponsavel,
      deletarAnexo,
      deletarAnexoUm,
      deletarDocR,
      docPaciente,
      deletarDocP,
      MenuMobileDois,
      TopCell,
    };
  },
};
</script>
<style>
@import url("../../site.css");
</style>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
  font-weight: 100;
}

.borda {
  padding: 5px;
  border: #16524e solid 1px;
  width: 100%;
  border-radius: 20px;
}

.conteudo_responsavel {
  padding: 20px;
  font-weight: 100;
}
.borda p {
  margin-bottom: 0;
  padding-left: 1rem;
}
.checkmark {
  border: #16524e solid 1px;
  border-radius: 10px;
}

header {
  background-color: rgb(22, 82, 78);
  padding-bottom: 50px;
  height: 90px;
}
#curso_selecionado {
  background-color: #fff;
}
.campo-obrigatorio {
  color: #84a4a1;
  font-size: 10pt;
  padding-left: 5px;
}
.title_curso {
  color: #fff;
  font-size: 25pt;
  padding-top: 20px;
  font-family: Yanone;
  letter-spacing: 0.2rem;
}
.btn_inscricao_loader {
  display: none !important;
}
.btn_inscricao {
  width: 150px;
  background: #1f6e69;
  color: #fff;
  font-size: 16pt;
  height: 50px;
  border: solid 2px #fff;
  border-radius: 20px;
  transition: 0.4s;
  margin-top: 5px;
  display: block;
  font-family: Yanone;
  font-weight: 100;
}
.div_img_curso_destaque {
  margin-top: 10rem;
  margin-bottom: 2rem;
}
.logoQuatro {
  margin-left: 0;
  z-index: 100;
  width: 20%;
}
.img_destaque_curso {
  border-radius: 20px;
}
.btn_div {
  text-align: center;
}
label {
  font-family: Yanone;
  font-size: 16pt;
  color: rgb(22, 82, 78);
  margin-bottom: 0;
}
.btn_deletar {
  padding: 0;
  border: none;
  background: none;
}
.img_deletar {
  width: 25px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
}
input {
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 30px;
  padding-left: none !important;
  font-size: 12pt;
}
textarea {
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 30px;
  padding-left: none !important;
  font-size: 12pt;
  resize: none;
}
input:focus {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
}
textarea:focus {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
}
input::placeholder {
  color: rgba(17, 62, 59, 0.551);
}
.div_form {
  padding: 20px;
  margin-top: 2rem;
}
.text_form {
  text-align: left;
  margin-top: 0rem;
}
.form_text {
  font-family: Yanone;
  font-weight: 500;
  color: #84a4a1;
  font-size: 46pt;
  line-height: 1.2;
}
.select_city {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 20px;
  padding-left: none !important;
  font-size: 12pt;
  width: 100%;
  background: #fff;
}
.select_city:focus {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
}
.icon_img {
  width: 100%;
}
.div_img {
  margin-top: 15rem;
  text-align: left;
}
.text_form_profissional {
  margin-top: 10rem;
}

.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 4.3rem;
  background-color: #fff;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  width: 80%;
}
/* Estilo personalizado para o círculo do botão de opção */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-container span {
  font-size: 16pt;
  border-radius: 10px;
  padding: 10px;
}
/* Estilo personalizado para o marcador do botão de opção */
.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #fff;
}
/* Estilo personalizado para o marcador quando selecionado */
.radio-container input:checked ~ .checkmark {
  background-color: none;
  border: solid 15px;
}
/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
/* Estilo personalizado para o ponto interno do marcador quando selecionado */
.radio-container input:checked ~ .checkmark::after {
  display: block;
}
/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after {
  top: 6px;
  left: 6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
}
.contribuir {
  font-weight: inherit;
  color: rgb(22, 82, 78);
}
.inputFile {
  width: 100%;
  height: 60px;
  position: relative;
  overflow: hidden;
  border: solid 1px rgb(22, 82, 78);
  border-radius: 20px;
  margin-bottom: 15px;
}
.inputFile input {
  opacity: 0;
  filter: alpha(opacity=0);
  right: 0;
  padding-top: 5px;
  z-index: 2;
  height: 80px;
  font-size: 100px;
  color: #000;
}
.arquivo {
  position: absolute;
  top: 15px;
  color: #16524e;
  margin-left: 15px;
  font-weight: 700;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .radio-container {
    display: inline-block;
    position: relative;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 14px;
    padding-left: 4.3rem;
  }

  label {
    font-size: 13pt;
  }
  .form_text {
    font-size: 30pt;
  }
  .logoQuatro {
    display: none;
  }
  .text_form {
    margin-top: 22rem;
  }
  .div_img {
    margin-top: 20rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .radio-container {
    display: inline-block;
    position: relative;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 14px;
    padding-left: 4.3rem;
  }
  .icon_img {
    display: none;
  }
  label {
    font-size: 15pt;
  }
  .form_text {
    font-size: 30pt;
  }
  .logoQuatro {
    display: none;
  }
  .arquivo {
    margin-left: 10px;
  }
  .btn_inscricao {
    width: 85%;
  }
  .btn_inscricao:hover {
    width: 90%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .logoQuatro {
    display: none;
  }
  .form_text {
    font-size: 20pt;
  }
  .div_img {
    margin-top: 0;
  }
  .text_form {
    text-align: center;
    margin-top: 0;
  }
  .icon_img {
    display: none;
  }
}
@media (max-width: 575px) {
  .titulo_cel {
    font-family: Yanone;
    font-size: 14pt;
    color: #feb715;
    padding-top: 1rem;
    text-align: right;
  }
  header {
    height: auto;
  }
  .icon_cel {
    margin-right: 20px;
    width: 60px;
    height: 60px;
  }
  .icon_img {
    display: none;
  }
  .logoQuatro {
    display: none;
  }
  .form_text {
    font-size: 20pt;
  }
  .text_form {
    text-align: center;
    margin-top: 0;
  }
  .div_img {
    margin-top: 0;
  }
  .div_nome_curso {
    width: 100%;
  }
}
</style>