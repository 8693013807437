export default httpClient => ({
  cadastrarPaciente: async (formPaciente) => {
    const response = await httpClient.post('/criar-pet-paciente', formPaciente)
    return {
      data: response.data,
    }
  },
  cadastrarProfissional: async (formProfissional) => {
    const response = await httpClient.post('/criar-mpp', formProfissional)
    return {
      data: response.data,
    }
  },
  saveEntrevista: async (hash, dados) => {

    const response = await httpClient.post('/entrevista-social/' + hash, dados);
    return { data: response.data }
  },
  aprovaCadastro: async (id) => {
    const response = await httpClient.get('/aprova-cadastro/' + id);
    return { data: response.data }
  },
  cancelaCadastro: async (id) => {
    const response = await httpClient.get('/cancela-cadastro/' + id);
    return { data: response.data }
  },
  //-------INFO-SISTEMA
  getAll: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/infos-sistema', { headers });
    return { data: response.data }
  },
  buscaData: async ({ data_inicial, data_final, token, }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.post('/infos-sistema', {
      data_inicial, data_final
    }, { headers })
    return {
      data: response.data,
    }
  },
  buscaPacientes: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/pacientes-ultimos-seis-meses', { headers });
    return { data: response.data }
  }
})
