<template>
  <Modal :open="state.modalOpen" @close="state.modalOpen = !state.modalOpen">
    <container>
      <button class="btn_transparent" @click="fecharModal">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          class="svg_fechar"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </button>
      <div>
        <a
          href="https://api.whatsapp.com/send/?phone=5544999409860&text&type=phone_number&app_absent=0"
          target="blank"
          style="display: flex"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-whatsapp svg-modal"
            viewBox="0 0 16 16"
          >
            <path
              d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
            />
          </svg>
          <p class="number_modal">(44)99940-9860</p></a
        >
      </div>
      <div>
        <a
          target="blank"
          style="display: flex"
          href="mailto:apracamassociacao@gmail.com?subject=Contato pelo site APRACAM!&body="
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-envelope svg-modal"
            viewBox="0 0 16 16"
          >
            <path
              d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"
            />
          </svg>
          <p class="number_modal">apracamassociacao@gmail.com</p>
        </a>
      </div>
    </container>
  </Modal>
  <div style="background-color: rgb(22, 82, 78)">
    <section id="home" class="d-none d-md-block">
      <div class="div-abs">
        <div @click="abrirModal" title="Fale Cononosco" class="btn-chat">
          <img class="icon-chat" src="./../../../public/img/contato.png" />
        </div>
      </div>
      <div class="zindex relative">
        <div class="container">
          <div class="row">
            <div class="col-md-4 zindex" style="text-align: center">
              <img class="logo" src="../../../public/img/logo.png" />
              <p class="title_home">FAÇA PARTE DA APRACAM</p>
              <p class="p_home">
                Clique no botão abaixo para ser um associado APRACAM
              </p>
              <div style="text-align: center">
                <a href="/cadastrar/PACIENTE">
                  <button class="btn_sup">QUERO ME ASSOCIAR</button></a
                >
              </div>
            </div>
            <div class="col-md-7 offset-1">
              <div>
                <img
                  class="img_home"
                  src="../../../public/img/apracam_img/img_home.jpg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="desenho_bottom zindex"></div>
    </section>
    <section id="home_cell" class="d-block d-md-none">
      <MenuMobile />

      <div class="div_back"></div>
      <div class="separator"></div>
      <div class="div-abs">
        <div @click="abrirModal" title="Fale Cononosco" class="btn-chat">
          <img class="icon-chat" src="./../../../public/img/contato.png" />
        </div>
      </div>
      <div class="div_img_cell">
        <img class="img_cell_logo" src="../../../public/img/logo.png" />
      </div>
      <div class="div_home_cel zindex">
        <p class="title_home_cel">FAÇA PARTE DA APRACAM</p>
      </div>
      <p class="p_home_cel zindex">
        Utilize o botão abaixo para ser um associado e ter benefícios da APRACAM
      </p>
      <div style="position: relative; z-index: 100; text-align: center">
        <a href="/cadastrar/PACIENTE">
          <button class="btn_sup">QUERO ME ASSOCIAR</button></a
        >
      </div>
    </section>
    <section id="servicos">
      <MenuMobile />
      <div class="desenho_top"></div>
      <div class="container">
        <div class="row">
          <div class="col-sm-6"><h1 class="title_servico">SERVIÇOS</h1></div>
          <div class="col-sm-6" style="text-align: right">
            <img class="logoDois zindex" src="../../../public/img/logo.png" />
            <Menu />
          </div>
        </div>
        <div class="zindex relative" style="margin-top: 70px">
          <div class="row">
            <div class="col-md-5 offset-md-1">
              <div class="div_icon">
                <div>
                  <img
                    class="juridc"
                    src="../../../public/img/icon_apracam/img_acolhimento.png"
                  />
                </div>
                <div>
                  <p class="opcaoOne">ACOLHIMENTO</p>
                  <p class="descricaoOpcao">
                    Fique tranquilo!<br />
                    Nossa equipe de acolhedores te ajudará nos primeiros passos
                    ao acesso completo a teraêutica.
                  </p>
                </div>
              </div>
              <div class="div_icon">
                <div>
                  <img
                    class="juridc"
                    src="../../../public/img/icon_apracam/img_medico.png"
                  />
                </div>
                <div>
                  <p class="opcaoOne">MÉDICOS ESPECIALIZADOS</p>
                  <p class="descricaoOpcao">
                    Interligamos de forma descomplicada e sem custos adicionais,
                    nossos pacientes aos médicos prescritores parceiros,
                    viabilizando o acesso ao tratamento com o óleo medicinal da
                    Cannabis.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="div_icon">
                <div>
                  <img
                    class="juridc"
                    src="../../../public/img/icon_apracam/img_apoio_terapeutico.png"
                  />
                </div>
                <div>
                  <p class="opcaoOne">APOIO TERAPÊUTICO MULTIDISCIPLINAR</p>
                  <p class="descricaoOpcao">
                    Contamos com uma equipe multidisciplinar de profissionais
                    capacitados propondo de forma personalizada suporte
                    terapêutico.
                  </p>
                </div>
              </div>
              <div class="div_icon">
                <div>
                  <img
                    class="juridc"
                    src="../../../public/img/icon_apracam/img_apoio_jur.png"
                  />
                </div>
                <div>
                  <p class="opcaoOne">SUPORTE JURÍDICO</p>
                  <p class="descricaoOpcao">
                    Disponibilizamos suporte jurídico para ações coletivas e
                    individuais, de educação e direitos sobre acesso à Cannabis
                    com fins medicinais.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="desenho_bottom zindex"></div>
    </section>
    <section id="equipe">
      <MenuMobile />
      <div class="desenho_top"></div>
      <div class="container">
        <div class="zindex relative">
          <div class="row">
            <div class="col-sm-6">
              <h1 class="title_servico">EQUIPE</h1>
            </div>
            <div class="col-sm-6" style="text-align: right">
              <img class="logoDois" src="../../../public/img/logo.png" />
              <Menu />
            </div>
          </div>
          <p class="title_quad_doutores">EQUIPE MULTIDISCIPLINAR APRACAM</p>
          <div class="quad">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="medico">
                  <div class="div_img">
                    <img
                      class="picture"
                      src="../../../public/img/apracam_img/pollyanna.png"
                    />
                  </div>
                  <div class="p_doutor">
                    <p class="nome_doutor">DRA. POLLYANA MORENO</p>
                    <p class="descricao_doutor">CRM-SC 29.513</p>
                    <p class="descricao_doutor">
                      Médica Indigenista, Pós graduada em fitoterapia e MTC,
                      especializada em terapia canábica.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="medico">
                  <div class="div_img">
                    <img
                      class="picture"
                      src="../../../public/img/apracam_img/agawa.png"
                    />
                  </div>
                  <div class="p_doutor">
                    <p class="nome_doutor">DR. FREDERIKO KEN AGAWA</p>
                    <p class="descricao_doutor">CRM-PR 40.106</p>
                    <p class="descricao_doutor">
                      Médico Graduado pela Universidade Federal de Pelotas-rs
                      Estudioso em Relação a Saúde e uso de Fitocanabinóides.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="medico">
                  <div class="div_img">
                    <img
                      class="picture"
                      src="../../../public/img/apracam_img/verderame.png"
                    />
                  </div>
                  <div class="p_doutor">
                    <p class="nome_doutor">DR. MAURÍCIO VERDERAME</p>
                    <p class="descricao_doutor">CRM-SP 70.527</p>
                    <p class="descricao_doutor">
                      Médico formado pela Santa casa de São Paulo em 1990.
                      Especialista em Homeopatia e Acupuntura desde 1999.
                      Trabalha com medicina Canábica desde 2019.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="medico">
                  <div class="div_img">
                    <img
                      class="picture"
                      src="../../../public/img/apracam_img/fernanda_loures.png"
                    />
                  </div>
                  <div class="p_doutor">
                    <p class="nome_doutor">DRA. FERNANDA LOURES</p>
                    <p class="descricao_doutor">CRO 19.347</p>
                    <p class="descricao_doutor">
                      Cirurgiã dentista desde 2007 Especialista em ortodontia e
                      Ofm e Cannabis medicinal.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="medico">
                  <div class="div_img">
                    <img
                      class="picture"
                      src="../../../public/img/apracam_img/barbara_martin.png"
                    />
                  </div>
                  <div class="p_doutor">
                    <p class="nome_doutor">BARBRA MARTINS DELGADO</p>
                    <p class="descricao_doutor">CRMV PR 20.040</p>
                    <p class="descricao_doutor">
                      Médica veterinária formada pela Universidade estadual do
                      norte Do Paraná - (UENP), pós Graduada em Cannabis
                      medicinal.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="elemento_equipe">
            <img
              src="../../../public/img/apracam_img/agendar_atendimento.png"
              class="img_agendar_atendimento"
            />
            <p class="p_equipe">QUERO AGENDAR UM ATENDIMENTO</p>
          </div>
          <div class="elemento_equipe">
            <a
              target="_blank"
              href="https://api.whatsapp.com/send/?phone=44999409860&text&type=phone_number&app_absent=0"
            >
              <button class="btn_prosseguir">PROSSEGUIR</button>
            </a>
          </div>
        </div>
      </div>
      <div class="desenho_bottom zindex"></div>
    </section>
    <section id="doe_apracam">
      <div class="desenho_top"></div>
      <MenuMobile />
      <div class="container">
        <div>
          <div class="row">
            <div class="col-sm-6">
              <h1 class="title_servico">DOE PARA A APRACAM</h1>
            </div>
            <div class="col-sm-6" style="text-align: right">
              <img class="logoDois" src="../../../public/img/logo.png" />
              <Menu />
            </div>
          </div>
          <div style="margin-top: 40px" class="row row_doe">
            <div class="col-lg-7 d-block d-sm-none">
              <img
                class="doe_img"
                src="../../../public/img/apracam_img/sua_doacao.jpg"
              />
            </div>
            <div class="col-lg-5">
              <img
                src="../../../public/img/icon_apracam/img_doe.png"
                class="img_doe"
              />
              <p class="sua_doacao">SUA DOAÇÃO MUDA TUDO!</p>
              <div style="clear: both"></div>
              <div class="descricao_doacao">
                <p>
                  Ampliar o acesso à cannabis para tratamentos de saúde no
                  Brasil é um dos nossos principais objetivos.
                </p>
                <p>
                  Contamos com a oferta de atendimentos para pacientes em
                  vulnerabilidades sociais. 20% dos nossos atendimentos são
                  destinados para este fim.
                </p>
                <p>
                  Para que isso continue precisamos da sua doação, pois quando
                  apresentada a real necessidade de apoio, podemos chegar a
                  descontos de 100%, fazendo todo o tratamento de forma
                  gratuita.
                </p>
                <p>
                  Só é possível realizar essa ação porque captamos recursos de
                  outros lugares, e entre eles estão as doações voluntárias.
                  Quando você faz uma doações, mais pessoas podem ser ajudadas!
                </p>
              </div>
              <div style="text-align: center">
                <a href="/doe/apoiador">
                  <button class="btn_doe">QUERO FAZER MINHA DOAÇÃO</button></a
                >
              </div>
            </div>
            <div class="col-lg-7 d-none d-sm-block">
              <img
                class="doe_img"
                src="../../../public/img/apracam_img/sua_doacao.jpg"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="desenho_bottom z-index"></div>
    </section>
    <!--<section id="cursos">
      <MenuMobile />
      <div class="desenho_top"></div>
      <div class="parte_escura_top_desk"></div>
      <div class="parte_escura_top_mobile"></div>
      <div class="container">
        <div class="zindex relative">
          <div class="row">
            <div class="col-md-6">
              <h1 class="title_servico">CURSOS</h1>
            </div>
            <div class="col-md-6" style="text-align: right">
              <img class="logoDois" src="../../../public/img/logo.png" />
              <Menu />
            </div>
          </div>
          <div><h1 class="nossos_cursos">CONHEÇA NOSSOS CURSOS</h1></div>
          <div class="row">
            <div class="col-md-6" v-for="curso in state.cursos" :key="curso.id">
              <div>
                <a :href="'/curso-destaque/' + curso.id">
                  <p class="nome_curso">{{ curso.nome }}</p>
                  <img class="cursos" :src="curso.imagem" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="parte_escura_desk"></div>
      <div class="parte_escura_mobile"></div>
      <div class="desenho_bottom zindex"></div>
    </section>-->
    <section id="colaboradores">
      <MenuMobile />
      <div class="desenho_top"></div>
      <div class="container">
        <div class="zindex relative">
          <div class="row">
            <div class="col-sm-6">
              <h1 class="title_servico">COLABORADORES</h1>
            </div>
            <div class="col-sm-6" style="text-align: right">
              <img class="logoDois" src="../../../public/img/logo.png" />
              <Menu />
            </div>
          </div>
          <div style="margin-top: 40px" class="row">
            <div class="col-lg-5">
              <img
                class="img_equipe"
                src="../../../public/img/icon_apracam/img_equipe.png"
              />
              <p class="volun_eqp">FAÇA PARTE DA NOSSA EQUIPE!</p>
              <div style="clear: both"></div>
              <p class="p_volunt">
                Se você tem interesse em participar da nossa equipe como
                profissional, voluntário ou tem interesse em fazer uma parceria
                institucional, estamos abertos a novas parcerias, entre em
                contato conosco!
              </p>
              <div style="text-align: center">
                <a href="/cadastrar/PARCEIRO">
                  <button class="btn_volunt">QUERO PARTICIPAR</button>
                </a>
              </div>
            </div>
            <div class="col-lg-7">
              <img
                class="img_volunt"
                src="../../../public/img/apracam_img/profissional_saude.jpg"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="desenho_bottom zindex"></div>
    </section>
    <section id="colaboradores">
      <MenuMobile />
      <div class="desenho_top"></div>
      <div class="container">
        <div class="zindex relative">
          <div class="row">
            <div class="col-sm-6">
              <h1 class="title_servico">DOE</h1>
            </div>
            <div class="col-sm-6" style="text-align: right">
              <img class="logoDois" src="../../../public/img/logo.png" />
              <Menu />
            </div>
          </div>
        </div>
        <div class="pix col-12 d-block d-md-none">
          <div class="faca_pix">
            <h1 class="faca_pix_1">Agora você pode nos ajudar fazendo <br>um pix!</h1>
            
          </div>
          <div class="div_pix">
            <div class="row">
              <div class="col-6">
                <textarea readonly id="textArea">
                    36.716.085/0001-56
                  </textarea
                >
              </div>
              <div class="col-6">
                <button
                  v-if="state.btn == false"
                  class="btn-copy"
                  @click="copyToClipBoard()"
                >
                  <img src="/img/copy_png.png" style="width:30px"/>
                </button>
                <button
                  v-if="state.btn == true"
                  class="btn-copy-check"
                  @click="copyToClipBoard()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-lg svg-pix"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="div-img">
            <img class="img-qrcode" src="../../../public/img/qrcode.png" />
            <h1 class="tipo">DADOS BANCÁRIOS</h1>
            <h1 class="ag">Ag: 0726 /</h1>
            <h1 class="ag">c/c: 55363-7</h1>
          </div>
        </div>
        <div style="background-color: #fff">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 d-none d-md-block">
                <div class="div_texto_contr">
                  <p class="texto_contribuicao">
                    Com sua contribuiçao podemos ofertar atendimentos gratuitos
                    para famílias em vunerabilidade social!
                  </p>
                </div>
              </div>
              <div class="col-lg-5 offset-lg-1 div_img_pix d-none d-md-block">
                <img src="../../../public/img/chave_pix.jpg" class="img-pix" />
              </div>
              <div class="col-lg-2 offset-lg-1 d-none d-md-block">
                <div class="div-img">
                  <img
                    class="img-qrcode"
                    src="../../../public/img/qrcode.png"
                  />
                  <h1 class="tipo">DADOS BANCÁRIOS</h1>
                  <h1 class="ag">Ag: 0726 /</h1>
                  <h1 class="ag">c/c: 55363-7</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="background-color: rgb(22, 82, 78)">
        <div class="container-fluid">
          <div class="row">
            <div class="col-6">
              <div style="text-align: right">
                <a href="#home">
                  <img
                    class="logo_bottom"
                    src="/img/apracam_img/logoFooter.png"
                /></a>
              </div>
            </div>
            <div class="division"></div>
            <div class="col-5">
              <div class="redes_sociais" style="text-align: left">
                <div class="row">
                  <div class="social">
                    <a
                      href="https://www.facebook.com/apracamassociacao"
                      target="blanl"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-facebook"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                        />
                      </svg>
                    </a>
                  </div>
                  <div class="social">
                    <a
                      href="https://www.instagram.com/apracamassociacao/"
                      target="blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-instagram"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
                        />
                      </svg>
                    </a>
                  </div>
                  <div class="social">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-youtube"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"
                      />
                    </svg>
                  </div>
                </div>
                <div class="row">
                  <div class="social_zap">
                    <a
                      href="https://api.whatsapp.com/send/?phone=44999409860&text&type=phone_number&app_absent=0"
                      target="blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-whatsapp"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                        />
                      </svg>
                    </a>
                    <span class="num_footer">(44)99940-9860</span>
                  </div>
                </div>
                <div class="row">
                  <span class="cnpj_span">CNPJ: 36.716.085/0001-56</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section id="doe">
      <MenuMobile />
      <div class="desenho_top"></div>
      <div class="container">
        <div>
          <div class="row">
            <div class="col-sm-6">
              <h1 class="title_doe">DOE</h1>
            </div>
            <div class="col-sm-6" style="text-align: right">
              <img class="logoDois" src="../../../public/img/logo.png" />
              <Menu />
            </div>
            <div class="pix col-12 d-block d-md-none">
              <div class="faca_pix">
                <h1 class="faca_pix_1">Agora você pode nos ajudar fazendo</h1>
                <h1 class="faca_pix_2">um pix!</h1>
              </div>
              <div class="div_pix">
                <span class="pixx">36.716.085/0001-56</span>
              </div>
              <div class="div-img">
                <img class="img-qrcode" src="../../../public/img/qrcode.png" />
                <h1 class="tipo">Dados Bancários</h1>
                <h1 class="ag">Ag: 0726 /</h1>
                <h1 class="ag">c/c: 55363-7</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="background-color: #fff">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 d-none d-md-block">
              <div class="div_texto_contr">
                <p class="texto_contribuicao">
                  Com sua contribuiçao podemos ofertar atendimentos gratuitos
                  para famílias em vunerabilidade social!
                </p>
              </div>
            </div>
            <div class="col-lg-5 offset-lg-1 div_img_pix d-none d-md-block">
              <img src="../../../public/img/chave_pix.jpg" class="img-pix" />
            </div>
            <div class="col-lg-2 offset-lg-1 d-none d-md-block">
              <div class="div-img">
                <img class="img-qrcode" src="../../../public/img/qrcode.png" />
                <h1 class="tipo">Dados Bancários</h1>
                <h1 class="ag">Ag: 0726 /</h1>
                <h1 class="ag">c/c: 55363-7</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="background-color: rgb(22, 82, 78)">
        <div class="container-fluid">
          <div class="row">
            <div class="col-6">
              <div style="text-align: right">
                <a href="#home">
                  <img
                    class="logo_bottom"
                    src="/img/apracam_img/logoFooter.png"
                /></a>
              </div>
            </div>
            <div class="division"></div>
            <div class="col-5">
              <div class="redes_sociais" style="text-align: left">
                <div class="row">
                  <div class="social">
                    <a
                      href="https://www.facebook.com/apracamassociacao"
                      target="blanl"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-facebook"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                        />
                      </svg>
                    </a>
                  </div>
                  <div class="social">
                    <a
                      href="https://www.instagram.com/apracamassociacao/"
                      target="blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-instagram"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
                        />
                      </svg>
                    </a>
                  </div>
                  <div class="social">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-youtube"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"
                      />
                    </svg>
                  </div>
                </div>
                <div class="row">
                  <div class="social_zap">
                    <a
                      href="https://api.whatsapp.com/send/?phone=5544999409860&text&type=phone_number&app_absent=0"
                      target="blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-whatsapp"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                        />
                      </svg>
                    </a>
                    <span class="num_footer">(44)99940-9860</span>
                  </div>
                </div>
                <div class="row">
                  <span class="cnpj_span">CNPJ: 36.716.085/0001-56</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->
  </div>
</template>
<script>
//import { useStorage } from "vue3-storage";
//import { useRouter } from "vue-router";
//import services from "../../services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Modal from "../../components/Modal/Modal.vue";
import { reactive, onMounted } from "vue";
import Menu from "../../components/MenuSite.vue";
import MenuMobile from "../../components/MenuMobile.vue";
export default {
  components: { Modal },
  setup() {
    // const storage = useStorage();
    //const router = useRouter();
    const state = reactive({
      // curso: [],
      cadastro: "",
      modalOpen: false,
      btn: false,
    });
    onMounted(() => {
      //fetchCursos();
    });
    function copyToClipBoard() {
      var content = document.getElementById("textArea");
      content.select();
      document.execCommand("copy");
      state.btn = true;
      toast.success("PIX Copiado!!!", {
        autoClose: 2000,
      });
    }
    function abrirModal() {
      state.modalOpen = true;
    }
    function fecharModal() {
      state.modalOpen = false;
    }

    return {
      state,
      Menu,
      MenuMobile,
      abrirModal,
      fecharModal,
      copyToClipBoard,
    };
  },
};
</script>
<style>
@import url("../../site.css");
</style>

<style scoped>
.btn-copy {
  margin-left: 5rem;
  background-color: transparent;
  color: rgb(22, 82, 78);
  padding: 5px;
  transition: 0.3s;
  border: none;
  margin-top: 2px;
}
.btn-copy-check {
  margin-left: 5rem;
  background-color: transparent;
  border: none;
  color: rgb(22, 82, 78);
  padding: 5px;
  transition: 0.3s;
  margin-top: 2px;
}
textarea {
  color: #16524e !important;
  text-align: left;
  font-size: 16pt;
  font-weight: 300;
  background: transparent;
  border: none;
  resize: none;
  height: 36px;
  margin-top: 1px;
}
textarea:focus {
  outline: none;
}
.svg-pix {
  color: rgb(22, 82, 78);
  opacity: 1;
}
.svg-pix:hover {
  color: rgb(22, 82, 78);
  opacity: 1;
}
.number_modal {
  color: #1f6e69;
  font-size: 18pt;
}
.btn-chat {
  position: fixed;
  z-index: 1000;
  background-color: #1f6e69;
  border: solid 3px;
  padding: 13px;
  border: solid 1px #1f6e69;
  border-radius: 100%;
  cursor: pointer;
  color: #1f6e69;
}
.icon-chat {
  filter: invert(100%);
}
.btn_transparent {
  background: transparent;
  float: right;
  border: none;
}
.btn_transparent svg {
  color: #000;
}
.btn_transparent svg:hover {
  color: rgb(236, 0, 0);
}

.div-abs {
  top: 39rem;
  left: 0.4rem;
  position: absolute;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
  font-weight: 100;
}
#cursos {
  display: none;
}

#home {
  background-color: rgb(22, 82, 78);
  min-height: 100vh;
  position: relative;
}
.zindex {
  z-index: 50;
}

.desenho_doe {
  width: 100%;
  position: absolute;
  height: 70px;
  margin-top: 0;
  background-image: url("../../../public/img/separator.png");
}

.div_img_cell {
  text-align: center;
  position: relative;
  margin-top: -100px;
}

.relative {
  position: relative;
}

.parte_escura_desk {
  width: 800px;
  height: 80%;
  margin-left: -400px;
  position: absolute;
  border-radius: 100%;
  bottom: 0;
  background-image: radial-gradient(
    circle at center,
    rgba(15, 63, 60, 0.7),
    rgba(15, 63, 60, 0.5),
    rgba(22, 82, 78, 0.1),
    rgba(22, 82, 78, 0)
  );
  z-index: 1;
}

.parte_escura_top_desk {
  width: 500px;
  height: 90%;
  position: absolute;
  border-radius: 100% 0 0 100%;
  top: -20;
  margin-top: -200px;
  right: 00px;
  background: -webkit-radial-gradient(
    right,
    circle,
    rgba(15, 63, 60, 0.7),
    rgba(15, 63, 60, 0.5),
    rgba(22, 82, 78, 0.3),
    rgba(22, 82, 78, 0),
    rgba(22, 82, 78, 0)
  );
  z-index: 1;
}
.svg-modal {
  color: #1f6e69;
  opacity: 1;
  margin: 5px;
}
.svg-modal:hover {
  color: #1f6e69;
}
#home .row {
  padding-bottom: 50px;
}
#home .container {
  padding-bottom: 50px;
  z-index: 10;
}

#home_cell {
  background-color: rgb(22, 82, 78);
  height: 125vh;
}
.div_back {
  background-image: url(../../../public/img/banner_cell/banner_cell_phone.jpg);
  height: 30vh;
  background-size: cover;
  background-repeat: no-repeat;
}
.quad {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: 10px 10px 20px 0px #000000b6;
}

.nome_curso {
  display: none;
}
.separatorDois {
  background-image: url(/img/bannerthree.46e11f79.jpg);
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(-35deg);
  margin-top: -25rem;
  margin-left: -25rem;
  width: auto;
}
.separator {
  width: 0;
  height: 0;
  border-left: 100vw solid transparent;
  border-bottom: 25.9vw solid #16524e;
  margin-top: -25.7vw;
  max-width: 100%;
}
.logo {
  width: 70%;
}
.img_home {
  width: 100%;
  margin-top: 80px;
}
.lateral_medicos {
  background-color: #a6a6a6;
  width: 5%;
  border-radius: 10px;
  height: 100%;
  border: #a6a6a6 solid 1px;
  padding-top: 50px;
  height: 95%;
}
.div_texto_contr {
  margin-top: 5rem;
}
.texto_contribuicao {
  font-family: Yanone;
  font-size: 30pt;
  font-weight: bold;
  color: #16524e;
  line-height: 1.2;
}
.div_img_pix {
  margin-top: 40px;
}

.title_home {
  color: #fff;
  font-weight: 400;
  text-align: center;
  font-size: 32pt;
  line-height: 1;
  font-family: Yanone;
  margin-top: 40px;
  padding: 20px;
}
.div_home_cel {
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title_home_cel {
  color: #ffc211;
  font-weight: 300;
  width: 60%;
  font-size: 23pt;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 0;
  font-family: Yanone;
  padding: 20px;
}
.p_home {
  color: #fff;
  font-size: 24pt;
  line-height: 1.2;
  font-family: Yanone;
  width: 60%;
  margin-left: 20%;
  text-align: justify;
  font-weight: 100;
}

.p_home_cel {
  position: relative;
  margin-top: 20px;
  color: #fff;
  text-align: center;
  line-height: 1.2;
  font-family: Yanone;
  width: 60%;
  margin-left: 20%;
  text-align: center;
  font-size: 16pt;
  font-weight: 400;
}
.btn_sup {
  font-weight: 100;
  width: 90%;
  height: 50px;
  background: #1f6e69;
  color: #fff;
  font-size: 20pt;
  border: solid 2px #fff;
  border-radius: 20px;
  transition: 0.4s;
  z-index: 100;
  font-family: Yanone;
}

.btn_sup_cel {
  font-weight: 100;
  width: 80%;
  background: #1f6e69;
  color: #fff;
  font-size: 16pt;
  height: auto;
  padding: 5px 8px;
  border: solid 2px #fff;
  border-radius: 20px;
  transition: 0.4s;
  font-family: Yanone;
}

#servicos {
  position: relative;
  background-color: rgb(22, 82, 78);
  margin-top: 20px;
  background-size: contain;
  min-height: 100vh;
  z-index: 10;
}
.desenho_top {
  background-image: url("../../../public/img/separator.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 70px;
  width: 100%;
  position: absolute;
  top: 0px;
}

.div_icon {
  display: flex;
  margin-bottom: 20px;
}
.title_servico {
  color: #fff;
  font-size: 25pt;
  padding-top: 85px;
  font-family: Yanone;
  font-weight: 300;
}
.title_doe {
  color: #fff;
  font-size: 25pt;
  padding-top: 85px;
  font-family: Yanone;
  font-weight: 300;
}
.opcaoOne {
  color: #feb715;
  font-size: 20pt;
  font-family: Yanone;
  font-weight: 100;
}
.descricaoOpcao {
  color: #fff;
  line-height: 1.3;
  font-family: Yanone;
  font-size: 18pt;
  font-weight: 100;
  text-align: justify;
}
.logoDois {
  width: 30%;
  position: relative;
  z-index: 50;
}
.iconServico {
  font-size: 30pt;
  color: #fff;
  background: #0d312f;
  padding: 10px;
  border-radius: 100%;
  margin-right: 15px;
}
.juridc {
  width: 70px;
  margin-right: 10px;
}
#equipe {
  background-color: rgb(22, 82, 78);
  margin-top: 20px;
  background-size: contain;
  position: relative;
}
#equipe .row {
  padding-bottom: 0px;
}
.title_quad_doutores {
  width: 100%;
  font-size: 20pt;
  text-align: left;
  color: #feb715;
  margin-bottom: 10px;
  font-family: Yanone;
  font-weight: 100;
}
.medico {
  display: flex;
}
.picture {
  width: 120px;
  border-radius: 50%;
  height: 120px;
  margin-top: 1rem;
  box-shadow: 5px 2px 10px 0px #000000d9;
}
.nome_doutor {
  margin-bottom: 0;
  color: #feb715;
  font-size: 16pt;
  font-family: Yanone;
  font-weight: 100;
}
.descricao_doutor {
  margin-bottom: 0;
  line-height: 1.3;
  color: #fff;
  font-size: 15pt;
  font-family: Yanone;
  font-weight: 100;
}
.p_doutor {
  padding: 15px;
}
.btn_prosseguir {
  margin-top: 15px;
  margin-bottom: 5rem;
  width: 200px;
  height: 50px;
  background: #1f6e69;
  color: #fff;
  border: solid 2px #fff;
  border-radius: 20px;
  transition: 0.4s;
  font-family: Yanone;
  font-weight: 100;
  font-size: 20pt;
}

.elemento_equipe {
  text-align: center;
}
.p_equipe {
  color: #fff;
  text-align: center;
  font-size: 24pt;
  line-height: 1.2;
  font-family: Yanone;
  font-weight: 100;
}
.img_agendar_atendimento {
  width: 150px;
}
#cursos {
  background-color: rgb(22, 82, 78);
  margin-top: 20px;
  background-size: contain;
  position: relative;
}
#cursos .row {
  padding-bottom: 0px;
}
#doe_apracam {
  background-color: rgb(22, 82, 78);
  margin-top: 20px;
  background-size: contain;
  position: relative;
  min-height: 100vh;
}

#doe_apracam .row_doe {
  padding-bottom: 80px;
  position: relative;
  z-index: 50;
}

.sua_doacao {
  color: #feb715;
  font-family: Yanone;
  font-size: 18pt;
  display: inline-block;
  margin-top: 20px;
  margin-left: 15px;
}
.doe_img {
  width: 100%;
}
.logoTres {
  position: absolute;
  margin-left: 0;
  left: auto;
  right: 0;
  z-index: 100;
  width: 30%;
}

.descricao_doacao p {
  color: #fff;
  text-align: justify;
  font-size: 18pt;
  line-height: 1.5;
  margin-bottom: 1px;
  font-family: Yanone;
}
.img_doe {
  width: 70px;
  height: 70px;
  float: left;
}

#colaboradores {
  background-color: rgb(22, 82, 78);
  margin-top: 20px;
  background-size: contain;
  position: relative;
}
.p_volunt {
  margin-top: 30px;
  color: #fff;
  text-align: justify;
  font-size: 18pt;
  line-height: 1.5;
  margin-bottom: 1px;
  font-family: Yanone;
}
.img_volunt {
  width: 100%;
}
.volun_eqp {
  color: #feb715;
  line-height: 1.2;
  font-family: Yanone;
  float: left;
  margin-top: 20px;
  margin-left: 15px;
  font-size: 20pt;
}
.img_equipe {
  width: 70px;
  height: 70px;
  float: left;
}
.social_zap {
  margin-top: 10px;
}
.btn_volunt {
  width: 250px;
  height: 50px;
  background: #1f6e69;
  color: #fff;
  font-size: 20pt;
  border: solid 2px #fff;
  border-radius: 20px;
  transition: 0.4s;
  margin-top: 40px;
  font-family: Yanone;
  font-weight: 100;
}
.btn_doe {
  width: 220px;
  height: 50px;
  background: #1f6e69;
  color: #fff;
  font-size: 16pt;
  padding: 10px;
  border: solid 2px #fff;
  border-radius: 20px;
  transition: 0.4s;
  margin-top: 25px;
  font-weight: 100;
  font-family: Yanone;
}

#pix_apracam {
  background-color: rgb(22, 82, 78);
  background-image: url(../../../public/img/apracam_img/divisonBot.png);
  background-repeat: no-repeat;
  margin-top: 20px;
  background-size: contain;
}
.pix_apracam {
  width: 100%;
}
.logoQuatro {
  position: absolute;
  margin-left: 0;
  left: auto;
  right: 110px;
  z-index: 100;
  width: 30%;
}
.img_cell_logo {
  margin-top: 100px;
  width: 40%;
  text-align: center;
}
.div_img {
}
#doe {
  background-color: rgb(22, 82, 78);
  background-repeat: no-repeat;
  margin-top: 50px;
  background-size: contain;
  height: 100vh;
}
.logo_bot {
  width: 20%;
  opacity: 0.5;
}
.logo_bottom {
  opacity: 0.5;
  width: 200px;
}
.division {
  background: #5f8885;
  width: 2px;
  margin-top: 15px;
  opacity: 0.5;
  margin-right: 20px;
}
svg {
  color: #fff;
  width: 30px;
  height: 30px;
  transition: 0.2s;
  opacity: 0.5;
}
svg:hover {
  color: #ffffff;
  opacity: 1;
}
.social {
  margin-top: 60px;
  margin-right: 10px;
}
.redes_sociais {
  margin-left: 30px;
}
.cnpj_span {
  color: #fff;
  font-size: 10pt;
  opacity: 0.5;
}
.num_footer {
  color: #fff;
  font-size: 12pt;
  opacity: 0.5;
  margin-left: 5px;
}
.img-qrcode {
  width: 100%;
  border: solid 2px #16524e;
}
.tipo {
  margin-bottom: 0;
  color: #feb715;
  margin-top:3px;
}
.ag {
  color: #16524e;
  margin-bottom: 0;
}
.div-img {
  margin-top: 5rem;
}
.img-pix {
  width: 100%;
}

.faca_pix_1 {
  font-family: Yanone;
  font-size: 30pt;
  z-index: 100;
  color: #fff;
  line-height: 1.2;
}

.faca_pix_2 {
  font-family: Yanone;
  font-size: 30pt;
  z-index: 100;
  color: #fff;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .texto_contribuicao {
    font-size: 25pt;
  }
  .ag {
    font-size: 17pt;
  }
  .btn_volunt {
    width: 85%;
  }
  .btn_volunt:hover {
    width: 90%;
  }
  .div-abs {
    top: 30rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .div-abs {
    top: 35rem;
  }
  .desenho_top {
    min-height: 45px;
  }
  .desenho_doe {
    height: 45px !important;
  }
  .texto_contribuicao {
    text-align: center;
  }
  .img-pix {
    width: 50%;
  }
  .div_img_pix {
    text-align: center;
  }
  .title_home {
    font-size: 20pt;
  }
  .p_home {
    font-size: 16pt;
  }
  .btn_sup {
    width: 100%;
  }

  .btn_volunt {
    width: 85%;
  }
  .btn_volunt:hover {
    width: 90%;
  }
  .opcaoOne {
    font-size: 15pt;
  }
  .nome_doutor {
    font-size: 16pt;
    line-height: 1.1;
  }
  .btn_prosseguir {
    width: 75%;
  }
  .btn_prosseguir:hover {
    width: 80%;
  }
  .sua_doacao {
    text-align: center;
    font-size: 16pt;
  }
  .title_servico {
    font-size: 21pt;
  }
  .title_doe {
    font-size: 21pt;
  }
  .row_doe {
    margin-top: 20px;
  }
  .descricao_doacao {
    text-align: justify;
  }
  .img-qrcode {
    width: 30%;
  }

  .div-img {
    text-align: center;
    margin-top: 1rem;
  }
  .btn_doe {
    width: 65%;
    margin-bottom: 2rem;
  }
  .btn_doe:hover {
    width: 70%;
  }
  .volun_eqp {
    text-align: center;
    font-size: 17pt;
  }
  .p_volunt {
    text-align: center;
    font-size: 14pt;
  }
  .btn_volunt {
    width: 70%;
    margin-bottom: 2rem;
  }
  .btn_volunt:hover {
    width: 75%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .div-abs {
    top: 35rem;
  }
  .desenho_top {
    min-height: 50px;
    width: 100%;
    position: absolute;
  }
  .title_servico {
    padding-top: 50px;
    font-size: 20pt;
  }
  .title_doe {
    padding-top: 80px;
  }

  .desenho_doe {
    height: 50px;
    margin-top: 0;
  }

  .img-qrcode {
    width: 50%;
  }
  .ag {
    color: #fff;
  }
  .div-img {
    margin-top: 1rem;
  }
  .p_home {
    color: #fff;
    text-align: center;
    font-size: 13pt;
    line-height: 1.1;
  }
  .title_home {
    color: #ffc211;
    font-weight: 900;
    text-align: center;
    font-size: 15pt;
    line-height: 1.2;
    margin-bottom: 0;
  }
  .sicredi {
    margin-bottom: 10px;
  }
  .text_cell {
    text-align: center;
  }
  #servicos {
    margin-top: 70px;
    background-color: rgb(22, 82, 78);
    background-image: none;
  }
  #equipe {
    background-color: rgb(22, 82, 78);
    background-image: none;
  }
  .logoDois {
    display: none;
  }
  .title_doe {
    color: #fff;
    font-size: 20pt;
    padding-top: 50px;
  }
  .agendar_atendimento {
    margin-top: 0;
  }
  #doe_apracam {
    background-color: rgb(22, 82, 78);
    background-image: none;
  }
  .logoTres {
    display: none;
  }
  .sua_doacao {
    font-size: 18pt;
  }
  .doe_img {
    margin-top: 20px;
  }
  #cursos {
    background-color: rgb(22, 82, 78);
    background-image: none;
  }
  #colaboradores {
    background-color: rgb(22, 82, 78);
    background-image: none;
  }
  .img_volunt {
    margin-top: 20px;
  }
  #doe {
    background-color: rgb(22, 82, 78);
    margin-top: 0px;
    background-image: none;
  }
  .logoQuatro {
    display: none;
  }
  .img_volunt {
    display: none;
  }
  .faca_pix {
  }
  .pix {
    text-align: center;
    margin-top: 20px;
  }
  .logo_bot {
    width: 50%;
  }
  .div_pix {
    background-color: #fff;
    padding: 10px;
    border: solid 1px #fff;
    border-radius: 100px;
    margin-left: 15px;
    margin-right: 15px;
    box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.31);
  }
  .pixx {
    color: #16524e;
    font-size: 15pt;
    font-weight: 700;
  }
  .sicredi {
    width: 100%;
    border-radius: 30px;
  }
  .div_sicredi {
    margin-top: 50px;
  }
  .division {
    height: 100px;
  }
}
@media (max-width: 575px) {
  .div-abs {
    top: 35rem;
  }
  .btn_volunt {
    margin-top: 80px;
  }

  .btn_sup {
    width: 60%;
  }
  .parte_escura_top_mobile {
    width: 300px;
    height: 90%;
    position: absolute;
    border-radius: 100% 0 0 100%;
    top: -20;
    margin-top: -200px;
    right: 00px;
    background: -webkit-radial-gradient(
      right,
      circle,
      rgba(15, 63, 60, 1),
      rgba(15, 63, 60, 0.9),
      rgba(22, 82, 78, 0.2),
      rgba(22, 82, 78, 0),
      rgba(22, 82, 78, 0)
    );
    z-index: 1;
  }
  .faca_pix_1 {
    font-size: 25pt !important;
  }
  .faca_pix_2 {
    font-size: 25pt !important;
  }
  .logo_bottom {
    width: 100%;
  }
  .img-qrcode {
    width: 50%;
  }
  .ag {
    color: #fff;
  }
  .div-img {
    margin-top: 1rem;
  }
  .parte_escura_mobile {
    width: 600px;
    margin-left: -300px;
    height: 50%;
    position: absolute;
    border-radius: 100%;
    bottom: 0;
    z-index: 1;
    background: -webkit-radial-gradient(
      circle,
      rgba(15, 63, 60, 1),
      rgba(15, 63, 60, 0.9),
      rgba(22, 82, 78, 0.2),
      rgba(22, 82, 78, 0),
      rgba(22, 82, 78, 0)
    );
  }
  .img_doe {
    display: none;
  }
  .desenho_bottom {
    background: none;
  }
  .btn_doe {
    font-size: 14pt;
  }
  .parte_escura_desk {
    display: none;
  }
  .parte_escura_top_desk {
    display: none;
  }

  .texto_contribuicao {
    display: none;
  }

  .picture {
    width: 70px;
    height: 70px;
  }
  .quad_doutores {
    border: none;
    box-shadow: none;
    background: none;
    width: 100%;
  }
  #home_cell {
    height: 100vh;
  }
  .desenho_top {
    min-height: 30px;
    width: 100%;
    position: absolute;
  }
  .title_servico {
    padding-top: 40px !important;
  }
  .title_doe {
    padding-top: 40px !important;
  }

  .desenho_doe {
    height: 50px;
    margin-top: -15px;
  }

  .p_home {
    color: #fff;
    text-align: center;
    font-size: 13pt;
    line-height: 1.1;
  }
  .title_home {
    color: #ffc211;
    font-weight: 900;
    text-align: center;
    font-size: 15pt;
    line-height: 1.2;
    margin-bottom: 0;
  }

  .text_cell {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    top: 700px;
  }
  #servicos {
    margin-top: 70px;
    background-color: rgb(22, 82, 78);
    background-image: none;
  }
  .opcaoOne {
    font-size: 15pt;
    line-height: 1.2;
  }
  .div_icon {
    margin-bottom: 20px;
  }
  .logoDois {
    display: none;
  }

  .title_doe {
    color: #fff;
    font-size: 20pt;
    padding-top: 20px;
  }
  .agendar_atendimento {
    margin-top: 0;
  }
  #doe_apracam {
    background-color: rgb(22, 82, 78);
    background-image: none;
  }
  .logoTres {
    display: none;
  }
  .sua_doacao {
    font-size: 18pt;
  }
  .doe_img {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  #cursos {
    background-color: rgb(22, 82, 78);
    background-image: none;
  }
  #colaboradores {
    background-color: rgb(22, 82, 78);
    background-image: none;
  }
  .img_volunt {
    margin-top: 20px;
  }
  #equipe {
    background-color: rgb(22, 82, 78);
    margin-top: 10px;
    background-image: none;
  }

  .medico {
  }
  .nome_doutor {
    font-size: 15pt;
    line-height: 1.1;
  }
  .agendar_atendimento {
    line-height: 1.2;
  }
  .sua_doacao {
    text-align: center;
    line-height: 1.2;
    margin-left: 0;
  }
  .descricao_doacao {
    text-align: center;
    line-height: 1;
    text-align: left;
  }
  .btn_doe {
    border-radius: 20px;
  }
  .nossos_cursos {
    padding-bottom: 5px;
    text-align: left;
    font-size: 16pt;
  }
  #colaboradores {
    margin-top: 0;
    min-height: 80vh;
  }
  .volun_eqp {
    text-align: center;
    font-size: 19pt;
  }
  .p_volunt {
    text-align: justify;
  }
  .img_volunt {
    display: none;
  }
  #doe {
    background-color: rgb(22, 82, 78);
    margin-top: 0px;
    background-image: none;
  }
  .logoQuatro {
    display: none;
  }
  .faca_pix {
    color: #fff;
    font-weight: 900;
    font-size: 20pt;
    line-height: 1.1;
    width: 80%;
    margin-left: 10%;
  }
  .faca_pix_1 {
    font-family: Yanone;
    font-size: 55pt;
    z-index: 100;
    color: #fff;
  }
  .faca_pix_2 {
    font-family: Yanone;
    font-size: 55pt;
    z-index: 100;
    color: #fff;
  }
  .pix {
    text-align: center;
    margin-top: 20px;
  }
  .logo_bot {
    width: 100%;
  }
  .div_pix {
    position: relative;
    z-index: 100;
    background-color: #fff;
    padding: 10px;
    border: solid 1px #fff;
    border-radius: 100px;
    margin-left: 15px;
    margin-right: 15px;
    box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.31);
  }
  .pixx {
    color: #16524e;
    font-size: 15pt;
    font-weight: 700;
  }
  .sicredi {
    width: 100%;
  }
  .div_sicredi {
    margin-top: 50px;
  }
  .division {
    height: 130px;
  }
  .nome_curso {
    font-family: Yanone;
    color: #fff;
    text-transform: uppercase;
    font-size: 14pt;
    letter-spacing: 0.1rem;
    font-weight: 400;
    display: block;
  }
  .redes_sociais {
    margin-left: 0px;
  }
  .social {
    margin-top: 20px;
    z-index: 50;
  }
  #doe {
    height: 80vh;
  }
  .cnpj_span {
    font-size: 8pt;
  }
  .pix {
    z-index: 100;
  }
  .icon-chat {
    padding: 3px;
    width: 50px;
  }
}
</style>