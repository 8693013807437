<template>
  <modal-factory />
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8 header_gradiant"
    >
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item router_header">
                  <router-link to="/pacientes">Pacientes</router-link>
                </li>
                <li class="breadcrumb-item router_header">
                  <router-link to="/visualizar-paciente" class="primary"
                    >Visualizar
                  </router-link>
                </li>
              </ol>
            </nav>
            <form>
              <h2>Dados do Paciente:</h2>
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label>ID</label>
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      v-model="state.paciente.id"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Tipo</label>
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      v-model="state.paciente.tipo"
                    />
                  </div>
                </div>
                <div class="col-md-4" v-if="state.paciente.tipo == 'PET'">
                  <div class="form-group">
                    <label>Nome do Pet</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.nome_pet"
                    />
                  </div>
                </div>
                <div class="col-md-4" v-if="state.paciente.tipo == 'PACIENTE'">
                  <div class="form-group">
                    <label>Nome</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.pessoa.nome"
                    />
                  </div>
                </div>
                <div class="col-md-4" v-if="state.paciente.tipo == 'PACIENTE'">
                  <div class="form-group">
                    <label>Data de Nascimento</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.pessoa.data_nascimento"
                      v-mask-date.br
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Patologia</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.patologia"
                    />
                  </div>
                </div>
                <div class="col-md-4" v-if="state.paciente.tipo == 'PET'">
                  <div class="form-group">
                    <label>Nome do Tutor</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.pessoa.nome"
                    />
                  </div>
                </div>

                <div class="col-md-4" v-if="state.paciente.tipo == 'PACIENTE'">
                  <div class="form-group">
                    <label>Nome do Responsável</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.responsavel"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>GÊNERO</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.pessoa.genero"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Telefone</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.pessoa.telefone"
                      v-mask-phone.br
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.pessoa.email"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Cidade</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.pessoa.cidade"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Estado</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.pessoa.estado"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Rua</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.rua"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Número</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.numero"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Bairro</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.paciente.bairro"
                    />
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-md-4"
                    v-if="
                      state.paciente.arquivos.receita != null &&
                      state.paciente.arquivos.receita != undefined
                    "
                  >
                    <div class="quad_pdf">
                      <a
                        :href="state.paciente.arquivos.receita"
                        target="_blank"
                      >
                        <div v-if="state.arquivos.tipoPdfReceita != 'pdf'">
                          <p class="p_pdf">Baixar receita em Imagem</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-image"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                            />
                            <path
                              d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"
                            />
                          </svg>
                        </div>
                        <div v-else>
                          <p class="p_pdf">Baixar receita em PDF</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-filetype-pdf"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    class="col-md-4"
                    v-if="
                      state.paciente.arquivos.laudo != null &&
                      state.paciente.arquivos.laudo != undefined
                    "
                  >
                    <div class="quad_pdf">
                      <a :href="state.paciente.arquivos.laudo" target="_blank">
                        <div v-if="state.arquivos.tipoPdfLaudo != 'pdf'">
                          <p class="p_pdf">Baixar laudo em Imagem</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-image"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                            />
                            <path
                              d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"
                            />
                          </svg>
                        </div>
                        <div v-else>
                          <p class="p_pdf">Baixar laudo em PDF</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-filetype-pdf"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    class="col-md-4"
                    v-if="
                      state.paciente.arquivos.documento_responsavel != null &&
                      state.paciente.arquivos.documento_responsavel != undefined
                    "
                  >
                    <div class="quad_pdf">
                      <a
                        :href="state.paciente.arquivos.documento_responsavel"
                        target="_blank"
                      >
                        <div v-if="state.arquivos.tipoPdfDocumento != 'pdf'">
                          <p class="p_pdf">Baixar documento em Imagem</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-image"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                            />
                            <path
                              d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"
                            />
                          </svg>
                        </div>
                        <div v-else>
                          <p class="p_pdf">Baixar documento em PDF</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-filetype-pdf"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    class="col-md-4"
                    v-if="
                      state.paciente.arquivos.doc_paciente != null &&
                      state.paciente.arquivos.doc_paciente != undefined
                    "
                  >
                    <div class="quad_pdf">
                      <a
                        :href="state.paciente.arquivos.doc_paciente"
                        target="_blank"
                      >
                        <div v-if="state.arquivos.tipoPdfDocumentoPaciente != 'pdf'">
                          <p class="p_pdf">Baixar documento do Paciente</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-image"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                            />
                            <path
                              d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"
                            />
                          </svg>
                        </div>
                        <div v-else>
                          <p class="p_pdf">
                            Baixar documento do Paciente em PDF
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-filetype-pdf"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    class="col-md-4"
                    v-if="
                      state.paciente.arquivos.comprovante_residencia != null &&
                      state.paciente.arquivos.comprovante_residencia !=
                        undefined
                    "
                  >
                    <div class="quad_pdf">
                      <a
                        :href="state.paciente.arquivos.comprovante_residencia"
                        target="_blank"
                      >
                        <div v-if="state.arquivos.tipoPdfComprovante != 'pdf'">
                          <p class="p_pdf">
                            Comprovante de Residência em Imagem
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-image"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                            />
                            <path
                              d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"
                            />
                          </svg>
                        </div>
                        <div v-else>
                          <p class="p_pdf">Comprovante de Residência em PDF</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-filetype-pdf"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="div_btns">
                  <router-link to="/admin/pacientes">
                    <button
                      class="btn btn_cancelar"
                      id="submit-button"
                      type="button"
                    >
                      Cancelar
                    </button>
                  </router-link>
                  <button
                    class="btn btn_salvar"
                    id="submit-button"
                    type="button"
                    @click="upPaciente()"
                  >
                    Atualizar
                  </button>
                </div>
              </div>
            </form>
          </stats-card>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import services from "../../services";
import { useStorage } from "vue3-storage";

export default {
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    const router = useRouter();
    const state = reactive({
      paciente: { pessoa: {}, arquivos: {} },
      tipoPdfReceita: "",
      tipoPdfLaudo: "",
      tipoPdfComprovante: "",
      tipoPdfDocumento: "",
      tipoPdfDocumentoPaciente: "",
      arquivos: [],
    });
    onMounted(() => {
      if (router.currentRoute._value.params.id != undefined) {
        fetchPacientes();
      }
    });
    async function fetchPacientes() {
      try {
        const { data } = await services.paciente.obterPorId({
          id: router.currentRoute._value.params.id,
          token,
        });
        if (
          data.arquivos.receita != null &&
          data.arquivos.receita != undefined
        ) {
          const receita = data.arquivos.receita;
          const myArray = receita.split(".");
          var length = myArray.length;
          var pdf = myArray[length - 1];
          state.arquivos.tipoPdfReceita = pdf;
        }

        if (data.arquivos.laudo != null && data.arquivos.laudo != undefined) {
          const laudo = data.arquivos.laudo;
          const myLaudo = laudo.split(".");
          var cont = myLaudo.length;
          var pdfLaudo = myLaudo[cont - 1];
          state.arquivos.tipoPdfLaudo = pdfLaudo;
        }

        if (
          data.arquivos.comprovante_residencia != null &&
          data.arquivos.comprovante_residencia != undefined
        ) {
          const comprovante = data.arquivos.comprovante_residencia;
          const myComprovante = comprovante.split(".");
          var i = myComprovante.length;
          var pdfComprovante = myComprovante[i - 1];
          state.arquivos.tipoPdfComprovante = pdfComprovante;
        }
        if (
          data.arquivos.documento_responsavel != null &&
          data.arquivos.documento_responsavel != undefined
        ) {
          const documento = data.arquivos.documento_responsavel;
          const myDocumento = documento.split(".");
          var index = myDocumento.length;
          var pdfDocumento = myDocumento[index - 1];
          state.arquivos.tipoPdfDocumento = pdfDocumento;
        }
        if (
          data.arquivos.doc_paciente != null &&
          data.arquivos.doc_paciente != undefined
        ) {
          const documentoPaciente = data.arquivos.doc_paciente;
          const myDocumentoPAciente = documentoPaciente.split(".");
          var cnt = myDocumentoPAciente.length;
          var pdfDocumentoPaciente = myDocumentoPAciente[cnt - 1];
          state.arquivos.tipoPdfDocumentoPaciente = pdfDocumentoPaciente;
        }

        state.paciente = data;
      } catch (error) {
        console.log(error);
      }
    }
    async function upPaciente() {
      try {
        const { errors } = await services.paciente.update({
          token,
          id: state.paciente.id,
          nome: state.paciente.pessoa.nome,
          nome_pet: state.paciente.nome_pet,
          status: state.paciente.status,
          tipo_pessoa: state.paciente.pessoa.tipo_pessoa,
          tipo: state.paciente.tipo,
          email: state.paciente.pessoa.email,
          telefone: state.paciente.pessoa.telefone,
          genero: state.paciente.pessoa.genero,
          data_nascimento: state.paciente.pessoa.data_nascimento,
          endereco: state.paciente.pessoa.endereco,
          cidade: state.paciente.pessoa.cidade,
          estado: state.paciente.pessoa.estado,
        });
        if (!errors) {
          router.push({ name: "pacientes" });
          return;
        }
      } catch (error) {
        console.log(error);
      }
    }

    return {
      upPaciente,
      state,
    };
  },
};
</script>
    <style scoped>
.header_gradiant {
  background: linear-gradient(
    50deg,
    rgb(82, 181, 174) 0%,
    rgb(10, 36, 35) 100%,
    rgb(22, 82, 78)
  ) !important;
}
h2 {
  color: black;
  font-size: 18pt;
  font-weight: 700;
}
.btn_cancelar {
  background-color: rgb(255, 255, 255);
  color: rgb(10, 36, 35);
  border: solid 3px rgb(10, 36, 35);
  height: 50px;
  transition: 0.2s;
  margin-right: 15px;
  width: 20%;
  font-size: 11pt;
}
.btn_cancelar:hover {
  background-color: rgb(10, 36, 35);
  border: solid 3px rgb(10, 36, 35);
  color: #ffffff;
  height: 50px;
  transform: none;
}
.btn_salvar {
  background-color: rgb(255, 255, 255);
  border: solid 3px rgb(40, 149, 146);
  color: rgb(40, 149, 146);
  height: 50px;
  transition: 0.3s;
  width: 15%;
  font-size: 11pt;
}
.btn_salvar:hover {
  background-color: rgb(40, 149, 146);
  border: solid 3px rgb(40, 149, 146);
  color: rgb(0, 0, 0);
  height: 50px;
  transition: 0.3s;
  transform: none;
}
.div_btn {
  text-align: right;
}
input {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 12pt;
}
input:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
}
select {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  font-size: 12pt;
}
select:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
  font-size: 12pt;
}
label {
  color: #000;
  margin-bottom: 0;
  font-size: 14pt;
}
.div_btns {
  width: 100%;
  text-align: right;
}
.input_add_img {
  color: #000;
  border: solid 2px rgb(151, 110, 96);
  border-color: rgb(151, 110, 96);
  height: 150px;
  margin-bottom: 15px;
}
.input_add_img:focus {
  border: solid 2px rgb(0, 0, 0);
  border-color: rgb(0, 0, 0);
}
a {
  color: rgb(82, 181, 174);
}
a:hover {
  color: rgb(22, 82, 78);
}
svg {
  width: 50px;
  height: 50px;
  color: #000;
}
.quad_pdf {
  border: solid 2px #000;
  border-radius: 10px;
  margin-bottom: 40px;
  text-align: center;
  padding: 15px;
  width: 100%;
}
.p_pdf {
  color: #000;
  font-weight: 700;
}
</style>