import axios from 'axios'
import AuthService from './auth'
import ErroService from './erro'
import MedicosService from './medicos'
import CursosService from './cursos'
import PacienteService from './paciente'
import LoginService from './login'
import CadastrarService from './cadastrar'
import UsuarioService from './usuario'
import cepService from './cep'


const API_ENVS = {
  production: '',
  development: '',
  local: ' https://backendapracam.centrion.com.br/api/', //'http://192.168.3.12:8585/api/'  
  cep: 'http://viacep.com.br/ws/'

}
const httpClient = axios.create({
  baseURL: API_ENVS.local
})
const httpCep = axios.create({
  baseURL: API_ENVS.cep
})
export default {
  devServer: {
    proxy: 'https://backendapracam.centrion.com.br/api/'
  },
  auth: AuthService(httpClient),
  erro: ErroService(httpClient),
  medicos: MedicosService(httpClient),
  cursos: CursosService(httpClient),
  paciente: PacienteService(httpClient),
  login: LoginService(httpClient),
  cadastrar: CadastrarService(httpClient),
  usuario: UsuarioService(httpClient),
  cep: cepService(httpCep),


}