export default httpClient => ({
  getAll: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/pet-pacientes', { headers });
    return { data: response.data }
  },
  obterPorId: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/pet-pacientes/' + id, { headers });
    return { data: response.data }
  },
  update: async ({
    id, token, nome, nome_pet, tipo, status, tipo_pessoa, email, telefone, data_nascimento, genero, endereco, cidade, estado }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.patch('/pet-pacientes/' + id, {
      nome,
      nome_pet,
      status,
      tipo_pessoa,
      tipo,
      email,
      telefone,
      data_nascimento,
      genero,
      endereco,
      cidade, estado,
    }, { headers })
    return {
      data: response.data,
    }
  },
  delete: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.delete('/pet-pacientes/' + id, { headers })
    return { data: response }
  },
  getEntrevista: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/entrevistas-sociais', { headers });
    return { data: response.data }
  },
  getEntrevistaById: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/entrevistas-sociais/' + id, { headers });
    return { data: response.data }
  },
  updateEntrevista: async ({
    id, token, entrevista }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.patch('/entrevistas-sociais/' + id, entrevista, { headers })
    return {
      data: response.data,
    }
  },
  deleteEntrevista: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.delete('/entrevistas-sociais/' + id, { headers })
    return { data: response }
  },
})
