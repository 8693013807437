<template>
  <div class="d-flex d-sm-none">
    <TopCell />
  </div>
  <section id="curso_selecionado">
    <header>
      <div class="container">
        <div>
          <div class="row">
            <MenuMobileDois />
            <div class="col-md-6">
              <h1 class="title_curso">CADASTRO</h1>
              <div class="d-flex d-sm-none div_nome_curso">
                <img src="/img/icon_apracam/img_cursos.png" class="icon_cel" />
                <div class="titulo_cel">
                  FORMULARIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO
                  A CANNABIS MEDICINAL
                </div>
              </div>
            </div>
            <div class="col-md-6" style="text-align: right">
              <a href="/">
                <img class="logoQuatro" src="../../../public/img/logo.png"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="container">
      <div style="background-color: #fff; border-radius: 20px">
        <div class="row">
          <div class="col-md-5">
            <div class="div_form">
              <form>
                <div class="form-group">
                  <label for="nome">NOME</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input
                    type="text"
                    class="form-control"
                    id="nome"
                    placeholder="Ex: Maria Joana"
                    v-model="state.nome"
                  />
                </div>
                <div class="form-group">
                  <label for="email">SEU EMAIL</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Ex: meuemail@gmail.com"
                    v-model="state.email"
                  />
                </div>
                <div class="form-group">
                  <label for="phone">TELEFONE</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input
                    type="text"
                    class="form-control"
                    id="phone"
                    placeholder="Ex: (44) 99999-9999"
                    v-model="state.telefone"
                    v-mask-phone.br
                  />
                </div>
                <div class="form-group">
                  <label for="phone">DATA DE NASCIMENTO</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span
                  ><span style="font-size: 9pt" class="campo-obrigatorio"
                    >(Menores de 18 anos é obrigatório o
                    acompanhamento/documentos de um responsável legal!!!)</span
                  >
                  <input
                    type="date"
                    class="form-control"
                    id="phone"
                    v-model="state.data_nascimento"
                  />
                </div>
                <div class="form-group">
                  <label for="voce">GÊNERO</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <select
                    id="endereco"
                    class="select_city"
                    v-model="state.genero"
                  >
                    <option value="MASCULINO">MASCULINO</option>
                    <option value="FEMININO">FEMININO</option>
                    <option value="OUTROS">OUTROS</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="city">CEP</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input
                    class="form-control"
                    type="text"
                    @blur="cepAtributes"
                    v-model="state.cep"
                    v-mask-cep
                  />
                </div>
                <div class="form-group">
                  <label for="est">ESTADO</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input
                    v-model="state.estado"
                    id="est"
                    class="form-control"
                    placeholder="Ex: SP"
                  />
                </div>
                <div class="form-group">
                  <label for="city">CIDADE</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input
                    v-model="state.cidade"
                    id="city"
                    class="form-control"
                    placeholder="Ex: Campinas"
                  />
                </div>

                <div class="form-group">
                  <label for="endereco">RUA</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input v-model="state.rua" class="form-control" />
                </div>

                <div class="form-group">
                  <label for="endereco">BAIRRO</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input v-model="state.bairro" class="form-control" />
                </div>
                <div class="form-group">
                  <label>NÚMERO</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input v-model="state.numero" class="form-control" />
                </div>
                <div class="form-group">
                  <label for="voce">VOCÊ É?</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <select
                    id="voce"
                    class="select_city"
                    v-model="state.tipo_pessoa"
                  >
                    <option
                      v-if="state.tipo_cadastro != 'PARCEIRO'"
                      value="PACIENTE"
                    >
                      PACIENTE
                    </option>
                    <option
                      v-if="state.tipo_cadastro != 'PARCEIRO'"
                      value="TUTOR_PET"
                    >
                      TUTOR PET
                    </option>
                    <option
                      v-if="state.tipo_cadastro == 'PARCEIRO'"
                      value="PROFISSIONAL_SAUDE"
                    >
                      PROFISSIONAL DE SAÚDE
                    </option>
                    <option
                      v-if="state.tipo_cadastro == 'PARCEIRO'"
                      value="PESQUISADOR"
                    >
                      PESQUISADOR
                    </option>
                    <option
                      v-if="state.tipo_cadastro == 'PARCEIRO'"
                      value="PARCEIRO"
                    >
                      PARCEIRO
                    </option>
                  </select>
                </div>
                <div class="btn_div">
                  <button @click="cadastro" type="button" class="btn_inscricao">
                    PROSSEGUIR
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-6 offset-md-1">
            <div class="text_form">
              <h1 class="form_text">
                FORMULÁRIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO A
                CANNABIS MEDICINAL
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import services from "../../services";
import MenuMobileDois from "../../components/MenuMobileDois.vue";
import TopCell from "../../components/TopCell.vue";
import { reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  setup() {
    const router = useRouter();
    const storage = useStorage();
    const state = reactive({
      nome: "",
      email: "",
      telefone: "",
      data_nascimento: "",
      genero: "",
      estado: "",
      cidade: "",
      cep: "",
      rua: "",
      numero: "",
      bairro: "",
      tipo_pessoa: "",
      tipo_cadastro: "",
    });
    onMounted(() => {
      state.tipo_cadastro = router.currentRoute._value.params.tipo_cadastro;
      //  storage.setStorageSync("PARCEIRO", "");
    });

    async function cadastro() {
      if (state.nome == null || state.nome == undefined || state.nome == "") {
        toast.error("Insira um nome !", {
          autoClose: 2000,
        });
        return;
      }
      if (
        state.email == null ||
        state.email == undefined ||
        state.email == ""
      ) {
        toast.error("Insira um email !", {
          autoClose: 2000,
        });
        return;
      }
      if (
        state.telefone == null ||
        state.email == undefined ||
        state.telefone == ""
      ) {
        toast.error("Insira um telefone !", {
          autoClose: 2000,
        });
        return;
      }
      if (
        state.genero == null ||
        state.genero == undefined ||
        state.genero == ""
      ) {
        toast.error("Declare seu gênero !", {
          autoClose: 2000,
        });
        return;
      }
      if (
        state.estado == null ||
        state.estado == undefined ||
        state.estado == ""
      ) {
        toast.error("Insira um estado !", {
          autoClose: 2000,
        });
        return;
      }
      if (
        state.cidade == null ||
        state.cidade == undefined ||
        state.cidade == ""
      ) {
        toast.error("Insira uma cidade !", {
          autoClose: 2000,
        });
        return;
      }

      if (
        state.data_nascimento == null ||
        state.data_nascimento == undefined ||
        state.data_nascimento == ""
      ) {
        toast.error("Insira uma data de nascimento !", {
          autoClose: 2000,
        });
        return;
      }
      if (
        state.tipo_pessoa == null ||
        state.tipo_pessoa == undefined ||
        state.tipo_pessoa == ""
      ) {
        toast.error("Insira um tipo de pessoa !", {
          autoClose: 2000,
        });
        return;
      }
      storage.setStorageSync("nome", state.nome);
      storage.setStorageSync("email", state.email);
      storage.setStorageSync("telefone", state.telefone);
      storage.setStorageSync("genero", state.genero);
      storage.setStorageSync("estado", state.estado);
      storage.setStorageSync("cidade", state.cidade);
      storage.setStorageSync("cep", state.cep);
      storage.setStorageSync("endereco", state.endereco);
      storage.setStorageSync("data_nascimento", state.data_nascimento);
      storage.setStorageSync("pessoa", state.tipo_pessoa);
      storage.setStorageSync("rua", state.rua);
      storage.setStorageSync("numero", state.numero);
      storage.setStorageSync("bairro", state.bairro);
      /* if(state.tipo_pessoa == "PACIENTE"  || state.tipo_pessoa == "TUTOR_PET"){
        s
      }else{
        
      }*/
      router.push("/cadastro/" + state.tipo_pessoa);
    }
    async function cepAtributes() {
      var cep = state.cep;
      state.cep = cep.replace("-", "").replace(".", "");
      console.log(state.cep);
      try {
        const { data } = await services.cep.apiCep(state.cep);
        state.rua = data.logradouro;
        state.bairro = data.bairro;
        state.cidade = data.localidade;
        state.estado = data.uf;
        state.uf = data.uf;
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
    return {
      state,
      cadastro,
      MenuMobileDois,
      TopCell,
      cepAtributes,
    };
  },
};
</script>
<style>
@import url("../../site.css");
</style>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
  font-weight: 100;
}
header {
  background-color: rgb(22, 82, 78);
  padding-bottom: 50px;
  height: 90px;
}

.form-group {
  margin-bottom: 0.2rem;
}

#curso_selecionado {
  background-color: #fff;
}
.title_curso {
  font-weight: 300;
  color: #fff;
  font-size: 25pt;
  padding-top: 20px;
  font-family: yanone;
  letter-spacing: 0.2rem;
}
.campo-obrigatorio {
  color: #84a4a1;
  font-size: 10pt;
  padding-left: 5px;
}
.p_ex {
  color: rgb(22, 82, 78);
  font-size: 16pt;
  line-height: 1.2;
  text-align: justify;
}
.p_li {
  color: rgb(22, 82, 78);
  font-size: 16pt;
  line-height: 1.2;
  margin-bottom: 10px;
  text-align: justify;
}
.li_curso {
  color: rgb(22, 82, 78);
  font-size: 14pt;
  line-height: 1.2;
  text-align: justify;
}
.text_left_curso {
  padding: 40px;
  margin-top: 8rem;
}

.btn_inscricao {
  width: 140px;
  background: #1f6e69;
  color: #fff;
  font-size: 16pt;
  height: 50px;
  border: solid 2px #fff;
  border-radius: 20px;
  transition: 0.4s;
  margin-top: 5px;
  border: solid 2px #fff;
  font-family: Yanone;
  font-weight: 100;
}
.div_img_curso_destaque {
  margin-top: 10rem;
  margin-bottom: 2rem;
}
.logoQuatro {
  margin-left: 0;
  z-index: 100;
  width: 20%;
}
.img_destaque_curso {
  border-radius: 20px;
}
.btn_div {
  text-align: center;
}
label {
  font-size: 16pt;
  font-weight: 400;
  color: rgb(22, 82, 78);
  margin-bottom: 0;
  font-family: Yanone;
}
input {
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 30px;
  padding-left: none !important;
  font-size: 16pt;
  font-family: Yanone;
}
input:focus {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
}
input::placeholder {
  color: rgba(17, 62, 59, 0.551);
}
.div_form {
  padding: 20px;
  margin-top: 2rem;
}
.text_form {
  text-align: left;
  margin-top: 10rem;
}
.form_text {
  font-weight: 500;
  color: #84a4a1;
  font-size: 60pt;
  line-height: 1.2;
  font-family: Yanone;
}
.select_city {
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 20px;
  padding-left: none !important;
  font-size: 12pt;
  width: 100%;
  background: #fff;
}
.select_city:focus {
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
}
@media (min-width: 992px) and (max-width: 1200px) {
  .logoQuatro {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .form_text {
    font-size: 30pt;
  }
  .logoQuatro {
    display: none;
  }
  .btn_inscricao {
    width: 90%;
  }
  .btn_inscricao:hover {
    width: 95%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .logoQuatro {
    display: none;
  }
  .text_form {
    text-align: center;
    margin-top: 0;
  }
  .btn_inscricao {
    width: 90%;
  }
  .btn_inscricao:hover {
    width: 95%;
  }
  .form_text {
    font-size: 50pt;
  }
}
@media (max-width: 575px) {
  .icon_cel {
    margin-right: 20px;
    width: 60px;
    height: 60px;
  }
  .titulo_cel {
    font-family: Yanone;
    font-size: 14pt;
    color: #feb715;
    padding-top: 1rem;
    text-align: right;
  }
  header {
    height: auto;
  }
  .logoQuatro {
    display: none;
  }
  .text_form {
    text-align: center;
    margin-top: 0;
  }
  .form_text {
    font-size: 20pt;
    line-height: 1.2;
  }
  .div_nome_curso {
    width: 100%;
  }
}
</style>